import { call, put, takeEvery } from "redux-saga/effects";
import { getCubejsApi, redirectToLogin } from "../../components/common/Dashboard/cubejs-util";
import { 
  FETCH_TOTAL_ASSETS_COUNT,
  SET_TOTAL_ASSETS_COUNT,
  SET_ONEDRIVE_ASSETS_COUNT,
  SET_GOOGLEDRIVE_ASSETS_COUNT,
  SET_DROPBOX_ASSETS_COUNT,
  SET_NTFS_ASSETS_COUNT,
  GET_DATA_SOURCE_EXISTENCE_DATA,
  SET_DATA_SOURCE_EXISTENCE_DATA,
} from "../constants/constants";
import { isEmpty } from "lodash";

function* fetchDataSourcesAndCounts() {
  const cubejsApi = getCubejsApi();
  let dataSourceExistenceResponse, totalAssetsCounts = {}, totalAssetsCount = 0;

  const dataSourceExistenceQuery = {
    dimensions: [
      "DataSourceExistence.typeCode",
      "DataSourceExistence.existsInDataSource",
    ],
    order: { "DataSourceExistence.typeCode": "asc" },
  };

  try {
    // Fetch data source existence
    const dataSourceExistenceData = yield cubejsApi.load(dataSourceExistenceQuery);
    dataSourceExistenceResponse = dataSourceExistenceData.rawData();

    if (!isEmpty(dataSourceExistenceResponse)) {
      const formattedData = dataSourceExistenceResponse.map((e) => ({
        type: e["DataSourceExistence.typeCode"] || "Unknown",
        present: e["DataSourceExistence.existsInDataSource"] || "FALSE",
      }));

      yield put({ type: SET_DATA_SOURCE_EXISTENCE_DATA, payload: formattedData });

      // Filter only the data sources marked as present
      const availableDataSources = formattedData.filter((source) => source.present === "TRUE");

      // Fetch total asset counts for each available data source
      for (const source of availableDataSources) {
        const query = {
          measures: ["Assets.count"],
          filters: [
            {
              member: "Assets.datasourceType",
              operator: "equals",
              values: [source.type],
            },
          ],
        };

        try {
          const result = yield cubejsApi.load(query);
          const rawCountData = result.rawData();
          if (!isEmpty(rawCountData)) {
            const count = rawCountData[0]["Assets.count"];
            totalAssetsCounts[source.type] = count;
            totalAssetsCount += parseInt(count);

            // Dispatch individual counts based on source type
            if (source.type === "ONEDRIVE") {
              yield put({ type: SET_ONEDRIVE_ASSETS_COUNT, payload: count });
            } else if (source.type === "DROPBOX") {
              yield put({ type: SET_DROPBOX_ASSETS_COUNT, payload: count });
            } else if (source.type === "GOOGLEDRIVE") {
              yield put({ type: SET_GOOGLEDRIVE_ASSETS_COUNT, payload: count });
            } else if (source.type === "NTFS") {
              yield put({ type: SET_NTFS_ASSETS_COUNT, payload: count });
            }
          }
        } catch (err) {
          console.error(`Error fetching count for ${source.type}`, err);
        }
      }
    }
  } catch (err) {
    redirectToLogin(err);
    return;
  }

  // Dispatch total assets count
  yield put({ type: SET_TOTAL_ASSETS_COUNT, payload: totalAssetsCount });
}


function* TotalAssetsCountSaga() {
  yield takeEvery(FETCH_TOTAL_ASSETS_COUNT, fetchDataSourcesAndCounts);
}

export default TotalAssetsCountSaga;