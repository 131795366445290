import React, { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import ReactDOM from "react-dom";
import AirplanemodeActiveIcon from "@material-ui/icons/AirplanemodeActive";
import { percentageCalculation } from "../../../common/utilities";

Chart.register(ArcElement);

const RiskPilotGaugeChart = ({ countData }) => {
  const speed = countData.totalClosedCount;

  const chartData = {
    datasets: [
      {
        data: [speed, countData.totalMitigationCount - speed],
        backgroundColor: [
          (context) => {
            const { ctx, chartArea } = context.chart;
            if (!chartArea) return null;

            const gradient = ctx.createRadialGradient(
              chartArea.centerX,
              chartArea.centerY,
              0,
              chartArea.centerX,
              chartArea.centerY,
              chartArea.radius
            );
            gradient.addColorStop(0, "#111111");
            gradient.addColorStop(0.8, "#53c072");

            return gradient;
          },
          "rgba(200, 200, 200, 0.3)", // Grey for the remaining part of the gauge
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    circumference: 180,
    rotation: -90,
    cutout: "80%",
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateRotate: true,
      onProgress: (animation) => {
        const chart = animation.chart;
        const progress = animation.currentStep / animation.numSteps;
        const percentage = percentageCalculation([{count: speed}], countData.totalMitigationCount)

        const { ctx } = chart;
        const data = chart.getDatasetMeta(0).data[0];

        const radius = data.outerRadius * 1.1; // Adjust radius to move the icon around the arc
        const centerX = data.x;
        const centerY = data.y;

        const angle = Math.PI * (percentage[0].count / 100) * progress + Math.PI;

        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);

        const iconId = "airplane-icon";
        let iconContainer = document.getElementById(iconId);

        const calculateFlightAngle = () => {
          if (percentage[0].count > 0 && percentage[0].count <= 85) {
            return angle + Math.PI / 1
          } else if (percentage[0].count > 85) {
            return angle + Math.PI / 2
          }
        }

        if (iconContainer) {
          iconContainer.style.left = `${x}px`;
          iconContainer.style.top = `${y}px`;
          iconContainer.style.transform = `translate(-50%, -50%) rotate(${
            calculateFlightAngle()
          }rad)`;
        }
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
      datalabels: {
        display: false,
      },
    },
  };

  useEffect(() => {
    const iconContainer = document.createElement("div");
    iconContainer.id = "airplane-icon";
    iconContainer.style.position = "absolute";
    iconContainer.style.transform = "translate(-50%, -50%)";
    const icon = (
      <AirplanemodeActiveIcon style={{ fontSize: 35, color: "#53c072" }} />
    );

    ReactDOM.render(icon, iconContainer);

    const chartContainer = document.getElementById("chart-container");
    chartContainer.appendChild(iconContainer);

    return () => {
      iconContainer.remove();
    };
  }, []);

  const speedLabel = {
    id: "speedLabel",
    afterDatasetsDraw: (chart) => {
      const { ctx } = chart;

      const data = chart.getDatasetMeta(0).data[0];
      const centerX = data.x;
      const centerY = data.y - 10;

      ctx.fillStyle = "#53c072";
      ctx.font = `bolder 1.5em roboto`;
      ctx.textAlign = "center";
      ctx.fillText(`${speed} / ${countData.totalMitigationCount}`, centerX, centerY);
    },
  };

  return (
    <div className="flightParentContainer">
      <div style={{ color: "#53c072", marginTop: "40%" }}>Source</div>
      <div
        style={{ position: "relative", width: "300px", height: "300px" }}
        id="chart-container"
      >
        <div
          style={{
            position: "absolute",
            top: "59%",
            left: "50%",
            width: "100%",
            height: "100%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Doughnut data={chartData} options={options} plugins={[speedLabel]} />
        </div>
      </div>
      <div style={{ color: "#53c072", marginTop: "40%" }}>Destination</div>
    </div>
  );
};

export default RiskPilotGaugeChart;
