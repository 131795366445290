import React, {useEffect, useState} from "react";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { getCubejsApi } from "../common/Dashboard/cubejs-util";
import { getChart } from "../common/Dashboard/chartTypeToComponent";
import {
  dashboardInstance,
  devopsDashboardInstance,
  dashboardInstanceForUser,
  // IncidentdashboardInstance,
  cloudFileSystemDashBoardInstance
} from "../common/Dashboard/dashboard-data";
import Modal from "@material-ui/core/Modal";
import "./DashboardStyles.scss";
import DashboardBanner from "../../components/shared/DashboardBanner";
import { useHistory } from "react-router-dom";
import TotalAssetsCount from "./TotalAssetsCount";
import { useDispatch, useSelector } from "react-redux";
import { ntfsDashBoardInstance } from "../common/Dashboard/NTFSDashboard/ntfsDashboardData";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    position: "absolute",
    width: 400,
    border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: "5px",
    backgroundColor: "#fff",
  },
});

export default function Dashboard() {
  const options = {
    'Compliance': [
      { value: 'Compliance', label: 'Compliance' },
      { value: 'User Data Access', label: 'User Data Access' }
    ],
    'ITManager': [
      { value: 'Data Assets', label: 'Data Assets' },
      { value: 'User Data Access', label: 'User Data Access' }
    ],
    'DevOps': [
      { value: 'DevOps', label: 'DevOps' },
    ],
    'DataManager': [
      { value: 'Compliance', label: 'Compliance' },
      { value: 'DevOps', label: 'DevOps' },
      { value: 'Data Assets', label: 'Data Assets' },
      { value: 'User Data Access', label: 'User Data Access' }
    ],
    'DataOwner': [
      { value: 'Compliance', label: 'Compliance' },
      { value: 'DevOps', label: 'DevOps' },
      { value: 'Data Assets', label: 'Data Assets' },
      { value: 'User Data Access', label: 'User Data Access' }
    ],
    'DataCustodian': [
      { value: 'Compliance', label: 'Compliance' },
      { value: 'DevOps', label: 'DevOps' },
      { value: 'Data Assets', label: 'Data Assets' },
      { value: 'User Data Access', label: 'User Data Access' }
    ],
    'Administrator': [
      { value: 'Compliance', label: 'Compliance' },
      { value: 'DevOps', label: 'DevOps' },
      { value: 'Data Assets', label: 'Data Assets' },
      { value: 'User Data Access', label: 'User Data Access' }
    ]
  }
  const dispatch = useDispatch();
  const classes = useStyles();
  let userRole = localStorage.getItem("user_role");
  let userDashboard;
  let currentDashboardName;
  const dataSourceExistence = useSelector(
    (state) => state.TotalAssetsCountReducer.dataSourceExistenceData
  );

  if (userRole.includes("DevOps")) {
    userDashboard = devopsDashboardInstance;
    currentDashboardName = "DevOps";
  } else if (userRole === "ITManager") {
    userDashboard = cloudFileSystemDashBoardInstance("ONEDRIVE");
    currentDashboardName = "Data Assets";
  } else if (userRole.includes('Compliance')) {
    userDashboard = dashboardInstance;
    currentDashboardName = 'Compliance';
  }else {
    userDashboard = dashboardInstanceForUser;
    currentDashboardName = "User Data Access";
  }

  const [dashboardConfig, setDashboardConfig] = React.useState(userDashboard);
  const [currentUser, setCurrentUser] = React.useState(currentDashboardName);
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  let history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };
  const changeUser = (event) => {
    setCurrentUser(event.target.value);
    let dashboardConfig;
    switch (event.target.value) {
      case "DevOps":
        dashboardConfig = devopsDashboardInstance;
        break;
      case "Compliance":
        dashboardConfig = dashboardInstance;
        break;
      case "User Data Access":
        dashboardConfig = dashboardInstanceForUser;
        break;
      case "Data Assets":
        dashboardConfig = cloudFileSystemDashBoardInstance("ONEDRIVE");
        break;
      default:
        dashboardConfig = dashboardInstance;
    }
    setDashboardConfig(dashboardConfig);
  };

  //Update dashboardConfig based the current tab
  const updateDashboardConfig = (activeTab) => {
    let dashboardConfig;
    switch (activeTab) {
      case "OneDrive":
        dashboardConfig = cloudFileSystemDashBoardInstance("ONEDRIVE");
        break;
      case "Dropbox":
        dashboardConfig = cloudFileSystemDashBoardInstance("DROPBOX");
        break;
      case "GoogleDrive":
        dashboardConfig = cloudFileSystemDashBoardInstance("GOOGLEDRIVE");
        break;
      case "NTFS":
        dashboardConfig = ntfsDashBoardInstance();
        break;
      default:
        dashboardConfig = cloudFileSystemDashBoardInstance("ONEDRIVE");
    }
    setDashboardConfig(dashboardConfig);
  };


  //To switch between oneDrive and DropBox
  const tabSwitch = () => {
    if (currentUser === "Data Assets") {
      return (
        <TotalAssetsCount onTabChange={updateDashboardConfig}/>
      );
    } else {
      return null;
    }
  }

  const renderClassStyles = () => {
    if (currentUser === "Data Assets") {
      return "main-dashboard-container switching"
    } else {
      return "main-dashboard-container";
    }
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Text in a modal</h2>
      <p id="simple-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </p>
    </div>
  );
  let cubejsApi = getCubejsApi();

  const renderGrid = (config) => (
    <Grid container spacing={1}>
      {config.map(({ row, size, id }, index) => (
        <Grid className={row} item xs={size} key={index}>
          {getChart(config, id, cubejsApi, undefined, undefined, undefined, history)}
        </Grid>
      ))}
    </Grid>
  );
  
  const renderMainGrid = () => {
    if (dataSourceExistence?.some(({ present }) => present === "TRUE")) {
      return renderGrid(dashboardConfig);
    }
  
    return (
      <div className="dashboardNoDataImageContainer">
        <img src="/dashboardIcons/NoDashboardData.svg" alt="No Data Available" />
        <span className="NoDataText">No Data Available</span>
        <span className="NoDataText">Data Assets dashboard is only available for cloud dataSource</span>
      </div>
    );
  };

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <DashboardBanner
        currentUser={currentUser}
        changeUser={changeUser}
        options={options}
        userRole={userRole}
      />
      <div className={renderClassStyles()} style={{ marginTop: "15px" }}>
        {tabSwitch()}
        {currentUser === "Data Assets"
          ? renderMainGrid()
          : renderGrid(dashboardConfig)}
      </div>
    </CubeProvider>
  );
}
