import React, { useEffect, useState } from "react";
import Toggler from "../shared/Toggler";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_TOTAL_ASSETS_COUNT,
  CLEAR_ALL_ASSETS_TOTAL_COUNT,
  ONEDRIVE,
  DROPBOX,
  GOOGLEDRIVE,
  NTFS,
} from "../../redux/constants/constants";

export default function TotalAssetsCount({ onTabChange }) {
  const dispatch = useDispatch();
  const totalAssetsCount = useSelector(
    (state) => state.TotalAssetsCountReducer.totalAssetsCount
  );
  const totalOneDriveAssetsCount = useSelector(
    (state) => state.TotalAssetsCountReducer.oneDriveAssetsCount
  );
  const dropBoxAssetsCount = useSelector(
    (state) => state.TotalAssetsCountReducer.dropBoxAssetsCount
  );
  const googleDriveAssetsCount = useSelector(
    (state) => state.TotalAssetsCountReducer.googleDriveAssetsCount
  );
  const ntfsAssetsCount = useSelector(
    (state) => state.TotalAssetsCountReducer.ntfsAssetsCount
  );
  const dataSourceExistenceData = useSelector(
    (state) => state.TotalAssetsCountReducer.dataSourceExistenceData
  );
  const [togglers, setTogglers] = useState([
    { name: "OneDrive", state: "disable", count: 0, type: ONEDRIVE },
    { name: "Dropbox", state: "disable", count: 0, type: DROPBOX },
    { name: "GoogleDrive", state: "disable", count: 0, type: GOOGLEDRIVE },
    { name: "NTFS", state: "disable", count: 0, type: NTFS },
  ]);

  useEffect(() => {
    dispatch({ type: FETCH_TOTAL_ASSETS_COUNT });

    return () => {
      dispatch({ type: CLEAR_ALL_ASSETS_TOTAL_COUNT });
    };
  }, []);

  useEffect(() => {
    // Create an array of togglers, each one with its updated count
    let updatedTogglers = [
      {
        name: "OneDrive",
        state: "disable",
        count: totalOneDriveAssetsCount,
        type: ONEDRIVE,
      },
      {
        name: "Dropbox",
        state: "disable",
        count: dropBoxAssetsCount,
        type: DROPBOX,
      },
      {
        name: "GoogleDrive",
        state: "disable",
        count: googleDriveAssetsCount,
        type: GOOGLEDRIVE,
      },
      { name: "NTFS", state: "disable", count: ntfsAssetsCount, type: NTFS },
    ];

    // Prioritize the first source with a count > 0, set it as 'active'
    const activeIndex = updatedTogglers.findIndex(
      (toggler) => toggler.count > 0
    );

    if (activeIndex !== -1) {
      updatedTogglers[activeIndex].state = "active"; // Set the first valid source to 'active'
      handleTabChange(updatedTogglers[activeIndex]?.name); // Automatically switch to that tab
    }

    // Filter togglers based on dataSourceExistenceData
    if (dataSourceExistenceData?.length) {
      updatedTogglers = updatedTogglers.filter((toggler) =>
        dataSourceExistenceData.some(
          (data) => data.type === toggler.type && data.present === "TRUE"
        )
      );
    }

    setTogglers(updatedTogglers);
  }, [
    totalOneDriveAssetsCount,
    dropBoxAssetsCount,
    googleDriveAssetsCount,
    ntfsAssetsCount,
    dataSourceExistenceData,
  ]);

  const handleTabChange = (activeTab) => {
    onTabChange(activeTab);
  };

  const renderTogglerClassname = () => {
    const length = dataSourceExistenceData.length;
    return `dashBoardToggler ${
      length === 1
        ? "one"
        : length === 2
        ? "two"
        : length === 3
        ? "three"
        : "rest"
    }`;
  };

  return (
    <div className="tabSwitch">
      {dataSourceExistenceData.some((d) => d.present === "TRUE") && (
        <>
          <div className="tab_switch_heading">
            <div className="tab_switch_heading_name">Total Assets</div>
            <div className="tab_switch_heading_count">{totalAssetsCount}</div>
          </div>
          <div className={renderTogglerClassname()}>
            <Toggler
              togglers={togglers}
              setTogglers={setTogglers}
              handleToggle={handleTabChange}
              loc="dashboard"
            />
          </div>
        </>
      )}
    </div>
  );
}
