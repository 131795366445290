import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import IdleTimer from "react-idle-timer";

import { Route, Switch, Redirect } from "react-router-dom";
import AdminContainer from "./components/layouts/AdminContainer/AdminContainer";
import MDRContainer from "./components/layouts/MDRContainer/MDRContainer";
import GovernorContainer from "./components/layouts/GovernorContainer/GovernorContainer";
import "./assets/scss/admin-styles.scss";
import LoginPage2 from "./components/views/Loginpage2";
import LoginMFA from "./components/views/LoginMFA";
import ForgotPassword from "./components/views/ForgotPassword/ForgotPassword";
import TenenantSignUp from "./components/views/TenantSignUp/TenenantSignUp";
import SignUpSuccesful from "./components/views/signUpPages/SignUpSuccesful";
import UserAccessRights from "./components/views/userAccessRights/UserAccessRights";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { getUserInfo } from "./redux/actions/getUserInfoAction";
import SignUpPage from "./components/views/signUpPages/SignUpPage";
import VerifyEmail from "./components/views/VerifyEmail/VerifyEmail";
import SetPassword from "./components/views/SetPasswordUser/SetPassword";
import SetPasswordSuccessful from "./components/views/SetPasswordUser/setPasswordSuccessful";
import { getUserPreferences } from "./redux/actions/getUserPreferences";
import { updateUserPreferences } from "./redux/actions/updateUserPreferences";
import { getSummaryTime } from "./components/common/utilities";
import MDRDashboard from "./components/views/DashboardMDR";
import { MDR, DDV, EXTERNAL_VIEW_REPORT_URL, platformNames, RISK } from "./redux/constants/constants";
import "./index.css";
import "./i18n";
import { isEmpty, isNull } from "lodash";

export const UserContext = React.createContext();

const App = (theme) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const signUpStatus = useSelector(
    (state) => state.selfSignUppage.signUpsuccessful
  );

  const setPasswordStatus = useSelector(
    (state) => state.setPasswordReducer.setPasswordSuccess
  );
  const [resetPasswordStatus, setresetPasswordStatus] = useState(false);
  const [alertsCount, setAlertsCount] = useState(0);

  // const setPasswordSuccess = useSelector(
  //   (state) => state.setPasswordReducer.setPasswordSuccess
  // );

  const isLoggedIn = useSelector((state) => state.signInpage.isloggedIn);
  const tracks = useSelector((state) => state.tracks.track);
  const [role, setRole] = useState("");
  const [token, setToken] = useState("");
  const [redirectRoute, setRedirectRoute] = useState("");
  const [previousData, setPreviousData] = useState("");
  const [newPreferences, setNewPreferences] = useState("");
  const [targetUrl, setTargetUrl] = useState("");
  let idleTimer = null;
  const queryParam = useLocation();
  // Retrieve the stored pathname from local storage to set as the redirect URL
  const redirectUrl = localStorage.getItem("storedPathname");
  const policyId = queryParam.search.slice(
    queryParam.search.indexOf("=") + 1,
    queryParam.search.indexOf("&")
  );
  const [tenant, setTenant] = useState("");
  const alertTotalCount = useSelector((state) => state.alertCount.totalCount);

  const buildData = () => {
    let historyList = [];
    let dateFilters;
    let todayDate = new Date();
    let today = `${getSummaryTime(todayDate)}`;
    let positions =
      previousData && previousData.nodePositions
        ? previousData.nodePositions
        : [];
    let alertsVisted = 0;
    if (window.location.pathname === "/tenant/alerts") {
      alertsVisted = alertTotalCount;
    } else {
      alertsVisted =
        previousData && previousData.alertsVisted
          ? previousData.alertsVisted
          : 0;
    }
    if (
      previousData &&
      previousData.historyList &&
      previousData.dateTimeFilters
    ) {
      dateFilters = previousData.dateTimeFilters;
      let todaysPreviousData = previousData.historyList.filter(
        (each) => each.date === today
      );
      if (todaysPreviousData.length > 0) {
        if (todaysPreviousData[0] && todaysPreviousData[0].pagesVisited) {
          todaysPreviousData[0].pagesVisited =
            todaysPreviousData[0].pagesVisited.concat(tracks);
        }
        previousData.historyList.map((each) => {
          if (each.date === today) {
            if (todaysPreviousData[0] && todaysPreviousData[0].pagesVisited) {
              historyList.push(todaysPreviousData[0]);
            }
          } else {
            historyList.push(each);
          }
          return null;
        });
      } else {
        historyList.push({
          date: today,
          pagesVisited: tracks,
        });
        previousData.historyList.map((each) => {
          historyList.push(each);
        });
      }
    } else {
      historyList.push({
        date: today,
        pagesVisited: tracks,
      });
    }
    // historyList.push({
    //       "date" : today,
    //       "pagesVisited" : []
    //     })
    let preferences = {
      historyList: historyList,
      dateTimeFilters: dateFilters ? dateFilters : {},
      timezone: "",
      nodePositions: positions,
      alertsVisted: alertsVisted,
    };
    setNewPreferences(preferences);
    setPreviousData("");
  };

  // To handle the extraction and preparation of the target URL
  useEffect(() => {
    // Check if a redirect URL is available
    if (redirectUrl) {
      // Extract the pathname from the redirect URL
      const pathName = redirectUrl;

      // Retrieve the stored ID from local storage
      const id = localStorage.getItem("storedId");

      // Check if an ID is available
      if (id) {
        // Set the target URL in the state, appending the ID as a query parameter
        setTargetUrl(`${pathName}?id=${id}`);
      } else {
        // Set the target URL without appending any query parameters
        setTargetUrl(pathName);
      }
    }
  }, []);

  useEffect(() => {
    if (previousData !== "" || previousData === null) {
      buildData();
    }
  }, [previousData]);

  useEffect(() => {
    async function savePreferences(preferences) {
      const response = await updateUserPreferences({
        preferences: JSON.stringify(preferences),
      });
      if (response && response.preferences) {
        dispatch({ type: "CLEAR_TRACK_TRAVERSAL" });
        setNewPreferences("");
      }
    }
    if (newPreferences !== "") {
      savePreferences(newPreferences);
    }
  }, [newPreferences]);

  useEffect(() => {
    async function getPreferences() {
      // Check if the 'tenant' is not included in the 'platformNames' array
      if (!platformNames.includes(tenant)) {
        const response = await getUserPreferences(tenant);
        setPreviousData(response);
      }
    }
    if (tracks && tracks.length > 0 && tenant) {
      getPreferences();
    }
  }, [tracks, tenant]);

  useEffect(() => {
    if (policyId !== "" && policyId !== null && policyId !== undefined) {
      setRedirectRoute(`/admin/alertPolicies/${policyId}`);
    }
  }, [policyId]);

  useEffect(() => {
    if (window.performance) {
      if (
        performance.navigation.type == 1 &&
        window.localStorage.getItem("access_token") !== null
      ) {
        dispatch(getUserInfo({ history }));
      }
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (isLoggedIn) {
      setToken(window.localStorage.getItem("access_token"));
      setRole(window.localStorage.getItem("user_role"));
      setTenant(window.localStorage.getItem("tenant"));
    }
    if (signUpStatus) {
      history.push("/SignUpSuccessful");
    }
  }, [isLoggedIn, signUpStatus, history]);

  function handleOnAction(event) {
    //console.log('user did something', idleTimer.getRemainingTime())
  }

  function handleOnActive(event) {
    //console.log('user is active', event)
    //console.log('time remaining', idleTimer.getRemainingTime())
  }

  function handleOnIdle(event) {
    localStorage.clear();
    sessionStorage.clear();
    if (window.location.host.indexOf("localhost") !== -1) {
      window.location.replace(
        `http://localhost:3000/?tenant=${localStorage.getItem("tenant")}`
      );
    } else {
      window.location.replace(`http://${window.location.host}/`);
    }
  }

  const redirectToRoute = () => {
    const featuresData = JSON.parse(localStorage.getItem("featuresInfo"));
    const features =
      featuresData &&
      featuresData.features &&
      featuresData.features.map((data) => data.name);

    if (
      token &&
      isLoggedIn &&
      tenant !== "druvstar" &&
      tenant !== "datavision" &&
      features &&
      features.includes(DDV)
    ) {
      if (!isEmpty(targetUrl)) {
        // Redirect to the target URL
        return <Redirect to={targetUrl}></Redirect>;
      } else {
        // Redirect to the default dashboard
        return <Redirect to={`/tenant/dashboard`}></Redirect>; // Change this URL to your default dashboard URL
      }
    } else if (
      (token && isLoggedIn && tenant === "druvstar") ||
      tenant === "datavision"
    ) {
      return <Redirect to={`/platform/tenantManagement`}></Redirect>;
    } else if (
      token &&
      isLoggedIn &&
      tenant !== "druvstar" &&
      tenant !== "datavision" &&
      features &&
      (features.includes(MDR))
    ) {
      return <Redirect to={`/MDRdashboards`}></Redirect>;
    }else if (
      token &&
      isLoggedIn &&
      tenant !== "druvstar" &&
      tenant !== "datavision" &&
      features &&
      (features.includes(RISK))
    ) {
      return <Redirect to={`/gov/GOVdashboards`}></Redirect>;
    } else {
      return <LoginPage2></LoginPage2>;
    }
  };

  return (
    <>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        timeout={3600000}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        onAction={handleOnAction}
        debounce={250}
      />
      <Switch>
        <Route exact path="/signup" component={SignUpPage} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route path="/SignUpSuccessful" component={SignUpSuccesful} />
        <Route path="/TenenantSignUp" component={TenenantSignUp} />
        {/*The below Routes are for verifying the email the User gets when he sign Up using  Self sevice Signup */}
        <Route path="/tenant/verifyEmail" component={VerifyEmail} />

        {/*The below route is for User Onboarded by TenantAdmin*/}
        <Route
          path="/users/activation/QRcode"
          component={SetPasswordSuccessful}
        ></Route>
        {/*The below route is for User Onboarded by TenantAdmin*/}
        <Route
          path="/users/activation"
          render={() =>
            setPasswordStatus ? (
              <Redirect to="/users/activation/QRcode"></Redirect>
            ) : (
              <SetPassword featureName="setPassword"></SetPassword>
            )
          }
        />
        <Route
          path="/resetpassword"
          render={() =>
            resetPasswordStatus ? (
              <Redirect to="/users/activation/QRcode"></Redirect>
            ) : (
              <UserContext.Provider value={setresetPasswordStatus}>
                <SetPassword featureName="resetPassword"></SetPassword>
              </UserContext.Provider>
            )
          }
        />
        <Route exact path="/" render={() => redirectToRoute()} />
        <Route
          exact
          path={`/mfaAuthentication`}
          render={() => redirectToRoute()}
        />
        <Route path={`/tenant`} component={AdminContainer} />

        <Route path={`/platform`} component={AdminContainer} />
        <Route path={`/test`} component={UserAccessRights} />
        <Route path={`/gov`} component={GovernorContainer} />
        <Route component={MDRContainer} />
      </Switch>
    </>
  );
};

export default App;
