import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { DataMapRoute } from "../common/DatamapImage";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CanAllow from "./CanAllow";
import { viewDataSource } from "../../redux/constants/constants";
const useStyles = makeStyles({
  banner: {
    height: "105px",
    paddingLeft: "215px",
    background: "linear-gradient(180deg, rgba(241,242,246,0) 0%, #DFE1E9 100%)",
    display: "flex",
    alignItems: "center",
  },
  mdrBanner: {
    gap: "20px",
    justifyContent: "unset",
  },
  bannerHeadingContainer: {
    width: "33%",
  },
  bannerHeading: {
    height: "40px",
    color: "#292929",
    fontFamily: "Nunito-Regular",
    fontSize: "32px",
    letterSpacing: "0",
    lineHeight: "40px",
    fontWeight: "bold",
  },
  bannerSubHeading: {
    color: "#292929",
    fontFamily: "Nunito-Regular",
    fontSize: "16px",
    letterSpacing: 0,
    lineHeight: "24px",
  },
  bannerTextInfo: {
    color: "#292929",
    fontFamily: "Nunito-Regular",
    fontSize: "16px",
    letterSpacing: 0,
    lineHeight: "24px",
  },
  BannerLeftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "auto"
  },
  BannerSuggestion: {
    height: "77px",
    borderRadius: "4px",
    marginRight: "16px",
    backgroundColor: "#081981",
    color: "#fff",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  BannerSuggestion_Text: {
    flex: "10",
    marginLeft: "18px"
  },
});

const DashboardBanner = (props) => {
  const {currentUser, changeUser, options, userRole, renderOptions} = props;
  const classes = useStyles();
  let history = useHistory();
  const displayName = localStorage.getItem("displayName");
  const goClikHandler = () => {
    history.push("/tenant/systemSummary");
  };

  const textInfoRender = () => {
    switch (currentUser) {
      case "DevOps":
        return "High level dashboard from DevOps point of view";
      case "Compliance":
        return "High level dashboard for compliance monitoring";
      case "User Data Access":
        return "High level dashboard with user-centered monitoring data";
      case "Data Assets":
        return "IT Managers view - Files";
      case "Incidents":
        return "High level dashboard for collected events and triggered alerts and incidents for MDR";
      case "Authentication":
        return "High level dashboard for authentication failures detected by MDR";
      case "Endpoints":
        return "High level dashboard for endpoints and agents in the network";
      case "Patch Monitoring":
         return "High level dashboard for software patch status on the servers in the network";
      case "Risk View":
          return "High level dashboard for Governor"
      default:
        return null;
    }
  };
  const renderUserOptions = () => {
    if (project !== "MDR") {
      const userRoleArray = userRole.split(",");
      const uniqueOptions = [];
      userRoleArray.map((role) => {
        if (options.hasOwnProperty(role)) {
          options[role].map((option) => {
            // Check if the option is already present in uniqueOptions
            const isOptionDuplicate = uniqueOptions.some(
              (existingOption) => existingOption.value === option.value
            );
            // If the option is not a duplicate, add it to uniqueOptions
            if (!isOptionDuplicate) {
              uniqueOptions.push(option);
            }
          });
        }
      });

      // Map the uniqueOptions array to create MenuItem elements
      return uniqueOptions.map((obj) => (
        <MenuItem key={obj.value} value={obj.value}>
          {obj.label}
        </MenuItem>
      ));
    }
    else {
      return renderOptions();
    }
  }
  const dashBoardSwitch = () => {
    return (
      <Select
        id="demo-simple-select"
        value={currentUser}
        onChange={changeUser}
        className="user-type-dropdown"
      >
        {renderUserOptions()}
      </Select>
    );
  };
  const project = props.project;
  return (
    <Grid className={project !== "MDR" ? `${classes.banner}` : `${classes.banner} ${classes.mdrBanner}` }>
      <section className={classes.bannerHeadingContainer}>
        <div className={classes.bannerHeading}>{currentUser === "Risk View" ? "Governor" : "Dashboard"}</div>
        {/* <div className={classes.bannerSubHeading}>Welcome {displayName}</div> */}
        <div className={classes.bannerTextInfo}>{textInfoRender()}</div>
      </section>
      {project === "MDR" && renderOptions === null ? null : (
        <section className='dashboard-selector'>
          {localStorage.getItem('user_role') !== 'DevOps' ? (
            <>Select Dashboard: {dashBoardSwitch()}</>
          ) : (
            ''
          )}
        </section>
      )}
      {project !== "MDR" &&
        <section className={classes.BannerLeftContainer}>
          <CanAllow needs={{permission: [viewDataSource]}}>
            <div className={classes.BannerSuggestion}>
              <div className={classes.BannerSuggestion_Text}>
                Suggested Actions Today
              </div>
              <Button
                variant="contained"
                className="ButtonGo"
                onClick={goClikHandler}
              >
                Go
              </Button>
            </div>
          </CanAllow>
          <DataMapRoute />
        </section>
      }
    </Grid>
  );
};

export default DashboardBanner;
