import React from "react";
import "./QuickLinks.scss";
import druvstarLogoFinal_whitesmall from "../../../assets/icons/druvstarLogoFinal_whitesmall.svg";
import DTILogosmall from "../../../assets/icons/DTILogosmall.svg";
import govfulllogo from "../../../assets/icons/govfulllogo.svg";
import govfulllogosmall from "../../../assets/icons/govfulllogosmall.svg";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MDR, DDV, RISK } from "../../../redux/constants/constants";
import MDRLogoSmall from "../../../assets/vizr_images/MDRLogoSmall.svg";
import SmallDruvstarLogo from "../../../assets/vizr_images/logo.svg";

const QuickLinks = ({ currentPage, shrunk=false, noMargin=false }) => {
    const QUICK_LINKS = {
        DDV: {
            src: druvstarLogoFinal_whitesmall,
            shrunkSrc: SmallDruvstarLogo,
                alt: "Datavision",
            onClick: () => {
                history.push("/tenant/dashboard");
                dispatch({
                    type: "TRACK_TRAVERSAL",
                    payload: "Dashboard",
                });
            }
        },
        MDR: {
            src: DTILogosmall,
            shrunkSrc: MDRLogoSmall,
            alt: "Threat Insights",
            onClick: () => {
                history.push("/mdr/MDRdashboards");
                dispatch({
                    type: "TRACK_TRAVERSAL",
                    payload: "Threat Insights Dashboard",
                });
            }
        },
        RISK: {
            src: govfulllogo,
            shrunkSrc: govfulllogosmall,
            alt: "Governor",
            onClick: () => {
                history.push("/gov/GOVdashboards");
            }
        }
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const items = [];
    const featuresData = JSON.parse(localStorage.getItem("featuresInfo"));
    const features =
        featuresData &&
        featuresData.features &&
        featuresData.features.map((data) => data.name);
    if (currentPage === RISK) {
        if (features && features.includes(DDV)) {
            items.push(QUICK_LINKS.DDV);
        }
        if (features && features.includes(MDR)) {
            items.push(QUICK_LINKS.MDR);
        }
    } else if (currentPage === DDV) {
        if (features && features.includes(MDR)) {
            items.push(QUICK_LINKS.MDR);
        }
        if (features && features.includes(RISK)) {
            items.push(QUICK_LINKS.RISK);
        }
    } else if (currentPage === MDR) {
        if (features && features.includes(DDV)) {
            items.push(QUICK_LINKS.DDV);
        }
        if (features && features.includes(RISK)) {
            items.push(QUICK_LINKS.RISK);
        }
    }
    return (
        items.length > 0 &&
        <div>
            {shrunk ? 
            <div className={(!noMargin && currentPage === DDV)?"quick-link-wrapper no-margin":"quick-link-wrapper"}>
                <div className="shrink-container">
                    {items[0] && <div className="item">
                        <img
                            src={items[0].shrunkSrc}
                            alt={items[0].alt}
                            onClick={items[0].onClick}
                        ></img>
                    </div>}
                    {items[1] && <>
                        <hr className="divider" />
                        <div className="item">
                            <img
                                src={items[1].shrunkSrc}
                                alt={items[1].alt}
                                onClick={items[1].onClick}
                            ></img>
                        </div>
                    </>}
                </div>
            </div>
             : <div className={(noMargin && currentPage === DDV)?"quick-link-wrapper no-margin":"quick-link-wrapper"}>
                <h4>Quick Links:</h4>
                <div className="quick-link-container">
                    {items[0] && <div className="item">
                        <img
                            src={items[0].src}
                            alt={items[0].alt}
                            onClick={items[0].onClick}
                        ></img>
                    </div>}
                    {items[1] && <>
                        <hr className="divider" />
                        <div className="item">
                            <img
                                src={items[1].src}
                                alt={items[1].alt}
                                onClick={items[1].onClick}
                            ></img>
                        </div>
                    </>}
                </div>
            </div>}
        </div>
    );
};

export default QuickLinks;