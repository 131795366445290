import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CubeProvider } from "@cubejs-client/react";
import Grid from "@material-ui/core/Grid";
import { RiskPilotDashboardInstance } from "../../views/RiskPilot/Dashboards/riskPilotDashboardData";
import { getChart } from "../../common/Dashboard/chartTypeToComponent";
import { getCubejsApi } from "../../common/Dashboard/cubejs-util";
import DashboardBanner from "../../shared/DashboardBanner";

function GovernorDashboard() {
    const [dashboardConfig, setDashboardConfig] = React.useState(RiskPilotDashboardInstance);
    let cubejsApi = getCubejsApi();
    const renderOptions = () => null;
    const changeUser = () => null;
    return (
        <CubeProvider cubejsApi={cubejsApi}>
            <DashboardBanner
                project="MDR"
                currentUser="Risk View"
                renderOptions={null}
                changeUser={changeUser}
            ></DashboardBanner>
            <div className="main-dashboard-container" style={{ paddingTop: "15px" }}>
                <Grid container spacing={1}>
                    {dashboardConfig &&
                        dashboardConfig.length > 0 &&
                        dashboardConfig.map((dashBoradinstance, index) => {
                            return (
                                <>
                                    <Grid
                                        key={index}
                                        className={dashBoradinstance.row}
                                        item
                                        xs={dashBoradinstance.size}
                                    >
                                        {getChart(dashboardConfig, dashBoradinstance.id, cubejsApi)}
                                    </Grid>
                                </>
                            );
                        })}
                </Grid>
            </div>
        </CubeProvider>
    )
}

export default React.memo(GovernorDashboard);