import React from 'react';
import './Badge.scss';
import { handleLowPercentage } from '../../common/utilities';
import { makeStyles } from 'material-ui-core';

/**
 * CircularProgressBar component renders a circular progress bar with a given percentage.
 *
 * @param {number} strokeWidth - The width of the progress bar stroke.
 * @param {number} percentage - The percentage of the progress to be displayed.
 * @param {string} severity - The severity level of the progress bar.
 * @param {number} width - The width of the SVG element.
 * @param {number} height - The height of the SVG element.
 * @param {number} titlePercentage - The percentage of the progress bar.
 * @returns {JSX.Element} CircularProgressBar component.
 */

const useStyles = makeStyles(() => ({
  'CircularProgressbar-path': {
    '&.high': {
      stroke: '#FF9EB3'
    },
    '&.medium':{
      stroke: '#FFBF8C'
    },
    '&.low':{
      stroke: '#80CAFF'
    },
    '&.not_processed':{
      stroke: '#E0AEFF'
    },
    '&.TODO': {
      stroke: '#FF9EB3'
    },
    '&.OPEN': {
      stroke: '#FFF4DE'
    },
    '&.INPROGRESS': {
      stroke: '#FEDECB'
    },
    '&.CLOSED': {
      stroke: '#65deb3'
    }
  },
  'CircularProgressbar-path-border': {
    '&.high': {
      stroke: '#E51959',
    },
    '&.medium': {
      stroke: '#FC6213',
    },
    '&.low': {
      stroke: '#081981',
    },
    '&.not_processed': {
      stroke: '#650176',
    },
    '&.TODO': {
      stroke: '#FF2251',
    },
    '&.OPEN': {
      stroke: '#ED9E00',
    },
    '&.INPROGRESS': {
      stroke: '#EF5B02',
    },
    '&.CLOSED': {
      stroke: '#05b778',
    },
  },
}))

export default function CircularProgressBar({ strokeWidth, percentage, severity, width, height, titlePercentage, count }) {
  const classes = useStyles ();
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
    M 50,50 m 0,-${radius}
    a ${radius},${radius} 0 1 1 0,${2 * radius}
    a ${radius},${radius} 0 1 1 0,-${2 * radius}
  `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    strokeLinecap: 'round',
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100 * diameter)}px`,
  };


  return (
    <div title={`${titlePercentage}%`}>
      <svg className='CircularProgressbar' viewBox="-2 -4 110 110" width={width} height={height}>

        <path
          className="CircularProgressbar-trail"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fill="transparent"
        />

        {/* Added extra path to add border around the circular progress bar */}
        <path
          className={`${classes['CircularProgressbar-path-border']} ${severity}`}
          d={pathDescription}
          strokeWidth={strokeWidth + 7} // Adjust the border width as needed
          fill="transparent"
          stroke="red"
          style={progressStyle}
        />

        <path
          className={`${classes['CircularProgressbar-path']} ${severity}`}
          d={pathDescription}
          strokeWidth={strokeWidth}
          fill="transparent"
          style={progressStyle}
        />

        <text
          className="CircularProgressbar-text"
          x={50}
          y={50}
          style={{
            fill: 'black',
            fontSize: '22px',
            fontWeight: '700',
            dominantBaseline: 'central',
            textAnchor: 'middle',
          }}
        >
          {handleLowPercentage(count, percentage, '<1%')}
        </text>
      </svg>
    </div>
  );
}
