import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import RefreshIcon from "../../../assets/vizr_images/ic_refresh.svg";
import "../../../assets/scss/admin-styles/custom/_variables.scss";
import { ColorButtonForAdminNavbar } from "../../common/buttons";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { getUserPreferences } from "../../../redux/actions/getUserPreferences";
import { updateUserPreferences } from "../../../redux/actions/updateUserPreferences";
import { AppBar, Toolbar} from "@material-ui/core";

import { timezones } from "../../common/timezones";
import { fetchApplicationData } from "../../../redux/actions/getAlertsAction";
import {
  viewApplication,
  GET_USER_PREFERENCES_URL,
  GET_TENANT_PROFILE,
} from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import { editTenant, renderTenantLogo } from "../../common/utilities";

const useStyles = makeStyles((theme) => ({
  appBg: {
    backgroundColor: "#081981",
  },
  appBgMDR: {
    // backgroundColor: "#5D0A90",
    backgroundColor: "#081981",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  welcomeTitle: {
    marginLeft: "200px",
    display: "flex",
    alignItems: "center",
    width: "39%",
  },
  tenantName:{
    fontSize: "18px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    color: "#081981",
    "&:hover": {
      backgroundColor: "white",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "#081981",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  year: {
    paddingLeft: `calc(1em + ${theme.spacing(0.5)}px)`,
    width: "342px !important",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function AdminNavbar({ project }) {
  const classes = useStyles();
  const [selectedTimeZone, setSelectedTimeZone] = React.useState(null);
  const permissions = useSelector((state) => state.applicationData.permissions);
  const [applications, setApplications] = useState([]);
  const [tenant, setTenant] = useState("");
  const tenantName = JSON.parse(localStorage.getItem('tenantName'));
  const applicationsList = useSelector(
    (state) => state.applicationData.applications
  );
  const selectedDateTimeRange = useSelector(
    (state) => state.selectedDateTimeRange.dateTimeRange
  );
  const [lastUpdated, setLastUpdated] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({type: GET_TENANT_PROFILE})
  },[])
  
  useEffect(() => {
    async function getUserPreferences() {
      const response = await getService({
        method: "GET",
        url: `${GET_USER_PREFERENCES_URL}`,
        headers: getAxiosHeaders(true),
      });
      if (response && response.data) {
        const parseData = JSON.parse(response.data.preferences);
        if (
          parseData &&
          parseData.nodePositions &&
          parseData.nodePositions.length > 0
        ) {
          dispatch({
            type: "NODE_POSITIONS",
            data: parseData.nodePositions.length ? parseData.nodePositions : [],
          });
        }
      }
    }
    getUserPreferences();
  }, []);

  useEffect(() => {
    var current = new Date();
    let hour = current.getHours();
    let minutes = current.getMinutes();
    let seconds = current.getSeconds();
    setLastUpdated(`${hour}:${minutes}:${seconds}`);
    setTenant(window.localStorage.getItem("tenant"));
  }, []);

  useEffect(() => {
    if (
      applicationsList.length === 0 &&
      permissions.indexOf(viewApplication) > -1
    ) {
      const getApplications = async () => {
        const res = await fetchApplicationData();
        //console.log(res);
        if (res && res.content) {
          setApplications(res.content);
        } else {
          console.log("Error  fetching data");
        }
      };
      getApplications();
    } else {
      setApplications(applicationsList);
    }
    setSystemTimezone();
  }, [applicationsList, viewApplication]);

  const logout = () => {
    if (window.location.host.indexOf("localhost") !== -1) {
      window.location.replace(
        `http://localhost:3000/?tenant=${localStorage.getItem("tenant")}`
      );
    } else {
      window.location.replace(`http://${window.location.host}/`);
    }
    localStorage.clear();
    sessionStorage.clear();
  };
  //setTimezone()
  const setSystemTimezone = () => {
    const sysTimezone = /\((.*)\)/.exec(new Date().toString())[1];
    const findSysTimezone = timezones.find(
      (item) => item.APIValue === sysTimezone
    );
    if (findSysTimezone) {
      setSelectedTimeZone(findSysTimezone);
    }
  };

  const editTenantProfile = (event, open) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(open);
  };
  const handleEditTenant = () =>{
    setOpen(false)
  }

  return (
    <div className={classes.grow}>
      <AppBar
        className={project ? classes.appBgMDR : classes.appBg}
        style={{ zIndex: 3 }}
        position="fixed"
      >
        <div className="mdrNavToolBarContainer">
          <Toolbar className="GrovernorNavToolBar">
            <div className={classes.welcomeTitle}>
              {renderTenantLogo()}
              {tenantName && (
                <div className={classes.tenantName} title={tenantName}>
                  {tenantName}
                </div>
              )}
              {editTenant(editTenantProfile, handleEditTenant, open, anchorEl)}
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <div
                style={{
                  padding: "0px 5px 0px 5px",
                  fontFamily: "$font-family-base",
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: "0",
                }}
              >
                <span style={{ opacity: "0.75", lineHeight: "18px" }}>
                  Last Updated
                </span>
                <br />
                <span
                className="last-updated-styles"
                >
                  {lastUpdated}
                </span>
              </div>
              <ColorButtonForAdminNavbar
                variant="contained"
                color="primary"
                size="small"
                className={classes.margin}
                onClick={() => {
                  window.location.reload();
                }}
              >
                <img alt="refresh" src={RefreshIcon} />
              </ColorButtonForAdminNavbar>
              &nbsp;&nbsp;
              <ColorButtonForAdminNavbar
                variant="contained"
                color="primary"
                size="small"
                className={classes.margin}
                onClick={() => logout()}
              >
                <PowerSettingsNewIcon />
              </ColorButtonForAdminNavbar>
            </div>
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
}
