import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import LevelComponent from "./levelComponent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import AlertDrawer from "./alertDrawer";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import EnhancedTableToolbar from "./enhancedToolBar";
import EnhancedTableHead from "./enhancedTableHead";
import TableHead from "@material-ui/core/TableHead";
import { getAllAlertPolicies } from "../../redux/actions/getAlertsPoliciesAction";
import { fetchAllAlerts } from "../../redux/actions/getAlertsAction";
import { filterAlerts } from "../../redux/actions/filterAlertsAction";
import { sortAlertsPolicies } from "../../redux/actions/sortAlertPoliciesAction";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { AddButtonForAlertTable } from "../common/buttons";
import { AddedButtonForAlertTable } from "../common/buttons";
import DropDownChevron from "../../assets/vizr_images/ic_chevron_down.svg";
import Popover from "@material-ui/core/Popover";
import { changeAlertPolicySeverity } from "../../redux/actions/changeAlertPolicySeverityAction";
import { AddDBButton } from "../common/buttons";
import InputBase from "@material-ui/core/InputBase";
import AddIcon from "@material-ui/icons/Add";
import { getTime, sanitizeDOMString, userPermission } from "../common/utilities";
import { DeleteButtonForToolBar } from "../common/buttons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Restore from "@material-ui/icons/Restore";
import Tooltip from "@material-ui/core/Tooltip";
import RolesContext from "../views/UserMangement/UserManagement";
import Grid from "@material-ui/core/Grid";
import { get, isEmpty } from "lodash";
import {
  setdatabaseAccessRequestId,
  singleUserExpiryDate_EditClick,
  openAccess_Drawer,
} from "../../redux/actions/userAccessrightsAction";

import {
  ALL_ROLES,
  sortBy_Filter_DatabaseUsers,
  CLEAR_CURRENT_STATUS,
  ALL_ACCESS,
  ALL_ACCESS_FILEPOPUP,
  ACCESS_ANOMALIES,
  ALL_USERS,
  SENSITIVE_ACCESS,
  DATASOURCE_DETAILS,
  sortBy_Filter_AccessDetails,
  sortBy_Filter_AccessDetails_Percentage,
  MDR,
  updateTenantUser,
  updatePlatformUser,
  deleteTenantUser,
  deletePlatformUser,
  viewDatabaseLogs,
  VIEW_ENTERPRISE_ACTORS,
} from "../../redux/constants/constants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";
import { createCustomGRidObject } from "../views/userAccessRights/UserAccessRightsCommonFunctions";
import {
  createCustomObject_AccessDetails,
  createCustomObject_AccessDetails_afterGettingSensitivePercentage,
} from "../views/userAccessRights/AccessDetails";
import "./tableStyles.scss";
import LoaderComponent from "../shared/LoaderComponent";
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import {
  colorFunction,
  sensitiveAccessColoringFunction,
} from "../shared/alertDrawer";
import { loopFunction } from "../../../src/components/views/userAccessRights/UserAccessRightsCommonFunctions";
import { loopFunction as AccessDetails_Loopfunction } from "../../components/views/userAccessRights/AccessDetails";
import AccessAnomalyTooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getAlertPolicyAlerts } from "../../redux/actions/getPolicyAlertsAction";
import { closeAccess_Drawer } from "../../redux/actions/userAccessrightsAction";
import { renderDateTime } from "../views/Util/ViewCommon";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { MDRTableCurrentStatusFinder } from "../../components/common/Dashboard/MDRHelpers";
import CanAllow from "./CanAllow";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  arrow: {
    fontSize: 16,
    width: 17,
    "&::before": {
      border: "1px solid #fff",
      backgroundColor: "white",
      boxSizing: "border-box",
      boxShadow: " 0 0 2px #0004",
    },
  },
}))(AccessAnomalyTooltip);

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "#081981",
  },
  inputInput: {
    boxShadow: "0 5px 8px -3px rgba(1,18,121,0.5)",
    borderRadius: "6px",
    marginRight: "10px",
    padding: "8px 20px",
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (get(b, orderBy) < get(a, orderBy)) {
    return -1;
  }
  if (get(b, orderBy) > get(a, orderBy)) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable(props) {
  const {
    handleToggleFilter,
    loc,
    headCells,
    checkbox,
    getAllAlertsCall,
    handleGetAllAlertsCall,
    getAllPoliciesCall,
    handleGetAllPoliciesCall,
    tableData,
    handleDataFlowGroupOpen,
    selectedDataFlowGroups,
    handleSelectDataFlowGroup,
    newAlertPolicyHide,
    deletePolicyBtnHide,
    handleAddClick,
    handleURLData,
    alertPolicyDataFlowGroupOpen,
    selectDataFlowGroupHandle,
    totalPolicyAlerts,
    handleGetPolicyAlerts,
    rolesClickHandler,
    setpaginationRows,
    permissonsClickHandler,
    totalElements,
    handleGetAllRolesCall,
    getAllRolesCall,
    handleGetAllActorsCall,
    getAllActorsCall,
    handleGetAllAccessDetails,
    getAllAccessDetailsCall,
    getAllAccess,
    getAllAccessFilePopUpData,
    handleGetAllAccessFilePopUp,
    handleGetAllAccess,
    handleGetAllAccessAnomalies,
    getAllAccessAnomalies,
    getAllUsersCall,
    handleGetAllUsersCall,
    getAllSensitiveInfo,
    handleGetSenstiveInfo,
    // setRolesData,
    dataLoaded,
    resetPage,
    setResetPage,
    currentSortDirection,
    setCurrentSortDirection,
    headerSortValue,
    project,
    type,
    getPolicyAlerts,
    policyId,
    description,
    setDataLoadedAccessDetails,
    setDataLoadedDatabaseUsers,
    setDataLoadedSenstiveAccess,
    setTotalElementsDatabaseUsers,
    color,
    handleGetAllAccessAnomalies_serviceDataAccesses,
    getAllAccessAnomalies_serviceDataAccesses,
    handleGetAllAccess_ServiceDataAccess,
    getAllAccess_ServiceDataAccess,
    handleGetSenstiveInfo_serviceDataAccesses,
    getAllSensitiveInfo_serviceDataAccess,
    handleGetDataSourceDetails_serviceDataAccesses,
    getAllDataSourceDetails_serviceDataAccesses
  } = props;

  const accessDetailsDrawer = useSelector(
    (state) => state.userAccessRightsReducer.accessDetailsDrawer
  );
  const searchValue = useSelector(
    (state) => state.userAccessRightsReducer.dbUsersSearch
  );
  const filterBy = useSelector(
    (state) => state.userAccessRightsReducer.filterValue
  );
  const sortByValue = useSelector(
    (state) => state.userAccessRightsReducer.sortByValue
  );
  const filterBySelectedValues = useSelector(
    (state) => state.userAccessRightsReducer.filterBySelectedValues
  );

  const filterByValue_AccessDetails = useSelector(
    (state) => state.accessDetails.filterValue
  );
  const sortByValue_AccesDetails = useSelector(
    (state) => state.accessDetails.sortByValue
  );

  const sortClickedAccessDetails = useSelector(
    (state) => state.accessDetails.sortClicked
  );

  const AccessDetailsSideDrawerData = useSelector(
    (state) => state.normalDirectDataSourceReducer.AccessDetailsSideDrawerData
  );
  const serviceDataAccessDetailsDrawerData = useSelector(
    (state) => state.serviceDataAccessReducer.ServiceAccessDetailsSideDrawerData
  );
//To get all access total count from redux that is fetched from api
  const allAccessTotalCount = useSelector( (state) => state.allAccessData.totalCount);

  const nodeId = useSelector((state) => state.selectedNode.selectedNodeId);
  const nodeSubType = useSelector((state) => state.selectedNode.selectedNodeSubType);
  const fieldId = useSelector(
    (state) =>
      state.dataMapReducer.dataMapFilePopUpData["Contents.sensitivityId"]
  );
  const lastAccessedTimeStamp = useSelector(
    (state) =>
      state.dataMapReducer.dataMapFilePopUpData["Contents.lastAccessTime"] 
  );

  useEffect(() => {
    if (loc === "database_Users") {
      setPage(0);
      setPagesLoaded([`${0}`]);
      // setRows([]);
    }
  }, [searchValue]);

  const isDeleted = useSelector((state) => state.policyDeleted.deleted);
  const policyCreated = useSelector((state) => state.policyCreated.created);
  const policyUpdated = useSelector((state) => state.policyUpdated.updated);
  const callback = useContext(RolesContext);
  const policyStatusUpdated = useSelector(
    (state) => state.policyStatusUpdated.policyStatusUpdated
  );

  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("risk");
  const [selected, setSelected] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState("");
  const [stateOpen, setStateOpen] = useState(false);
  const [sortBy, setSortBy] = useState(""); //use sort by to sort the array of alerts
  const [rows, setRows] = useState([]);
  const [resolvedAlerts, setResolvedAlerts] = useState([]);
  const [severityFilter, setSeverityFilter] = useState([]);
  const [resolutionFilter, setResolutionFilter] = useState([]);
  const [policyFilter, setPolicyFilter] = useState([]);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [severityPopUpOpen, setSeverityPopUpOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [selectedTenantForDelete, setSelectedTenantForDelete] = useState("");
  const [selectedRowForSeverity, setSelectedRowForSeverityChange] =
    useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [rowsUpdated, setRowsUpdated] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pagesLoaded, setPagesLoaded] = useState([`${0}`]);
  const [alertPage, setAlertPage] = useState(0);
  const [rolesPage, setRolesPage] = useState(0);
  const [actorsPage, setActorsPage] = useState(0);
  const [accessDetailsPage, setAccessDetailsPage] = useState(0);
  const [allAccessPage, setAllAccessPage] = useState(0);
  const [allAccessFilePopUpPage, setAllAccessFilePopUpPage] = useState(0);
  const [accessAnomaliesPage, setAccessAnomaliesPage] = useState(0);
  const [senstiveAccessPage, setSenstiveAccessPage] = useState(0);
  const [allAccessPage_serviceDataAccess, setAllAccessPage_serviceDataAccess] =
    useState(0);
  const [
    accessAnomaliesPage_serviceDataAccess,
    setAccessAnomaliesPage_serviceDataAccess,
  ] = useState(0);
  const [
    senstiveAccessPage_serviceDataAccess,
    setSenstiveAccessPage_serviceDataAccess,
  ] = useState(0);
  const [
    datasourceDetailsPage_serviceDataAccess,
    setdatasourceDetailsPage_serviceDataAccess,
  ] = useState(0);

  //sensitiveAccessColoringFunction

  const [usersPage, setUsersPage] = useState(0);

  localStorage.setItem("actorsPage", accessDetailsPage);

  const sortClicked = useSelector(
    (state) => state.userAccessRightsReducer.sortClicked
  );
  const dispatch = useDispatch();
  const severities = [
    { value: "HIGH", display: "HIGH" },
    { value: "MEDIUM", display: "MEDIUM" },
    { value: "LOW", display: "LOW" },
  ];


  //change table data to empty array when MDR Table search value chnages

  // const MDRUpdatedSearchValue = useSelector(
  //   (state) => state.IncidenetTableFilterReducer.MDRUpdatedSearchValue
  // );

  // useEffect(() => {
  //   console.log(rows);
  //   setRows([]);
  //   setTotalRows(0);
  //   setPage(0);
  //   setPagesLoaded([`${0}`]);
  // }, [MDRUpdatedSearchValue]);

  //change severity from grid
  const changeAlertPolicySeveritySave = (id, severity) => {
    async function changeSeverity(id, severity) {
      const response = await changeAlertPolicySeverity(id, severity);
      if (response) {
        dispatch({ type: "UPDATE_POLICY_STATUS_CHANGE_SUCCEEDED" });
        setSeverityPopUpOpen(false);
      }
    }
    if (id && severity) {
      changeSeverity(id, severity);
    }
  };

  const accessDetailsFilterClear = useSelector(
    (state) => state.accessDetails.accessDetailsFilterClear
  );

  useEffect(() => {
    if (accessDetailsFilterClear.value) {
      setAccessDetailsPage(0);
      setPagesLoaded([`${0}`]);
      setPage(0);
      // setDataLoadedAccessDetails(false);
      // setRows([]);
      // getAllAccessDetails("rowsChange");
      // handleGetAllAccessDetails(false);
    }
  }, [accessDetailsFilterClear]);

  useEffect(() => {
    setRowsPerPage(20);
    setOrder("asc");
    setOrderBy("risk");
    // clearing state on ummount
    return () => {
      localStorage.setItem("rowsperPage", 20);
      dispatch({ type: "SET_DATA_LOADED", payload: false });
    };
  }, [loc]);

  //get all the alert policies api call
  useEffect(() => {
    async function getAllPolicies() {
      let response = await getAllAlertPolicies(page, rowsPerPage);
      if (response && response.content) {
        setTotalRows(response.totalElements);
        setRows(response.content);
        setLoader(false);
        //put error code here
      }
    }
    if (getAllPoliciesCall) {
      setLoader(true);
      getAllPolicies();
      handleGetAllPoliciesCall(false);
    }
    return () => {
      setSelected([]);
    };
  }, [getAllPoliciesCall, handleGetAllPoliciesCall]);

  useEffect(() => {
    if (resetPage) {
      setPage(0);
      setPagesLoaded([`${0}`]);
      if (loc === "database_Users") {
        setActorsPage(0);
      } else if (loc === "Access_details") {
        setAccessDetailsPage(0);
      }
      setResetPage(false);
    }
  }, [resetPage]);

  const filterBy_dataBaseUsers = useSelector(
    (state) => state.userAccessRightsReducer.filterValue
  );
  async function getAllActors(changeValue, currentSortValue) {
    const headers = getAxiosHeaders(true);
    console.log(filterBySelectedValues, "filterBySelectedValues");
    const arr = loopFunction(filterBySelectedValues, filterBy_dataBaseUsers);
    const str = arr.join(",");
    const res = await getService({
      method: "GET",
      url: sortBy_Filter_DatabaseUsers(
        filterBy,
        currentSortValue ? currentSortValue : sortByValue,
        str,
        currentSortDirection,
        rowsPerPage,
        changeValue === "rowsChange" ? 0 : actorsPage
      ),
      headers: headers,
    });
    if (res && res.data) {
      setDataLoadedDatabaseUsers(true);
      let temp = rows;
      setTotalRows(
        res.data.totalElements
          ? res.data.totalElements
          : res.data.content.length
      );
      if (changeValue === "rowsChange") {
        setRows([...createCustomGRidObject(res.data.content)]);
      } else if (changeValue === "HeaderSortClick") {
        const DeletedArr = temp.splice(0, rowsPerPage * actorsPage);
        temp = res.data.content;
        setRows([
          ...new Set(DeletedArr.concat(...createCustomGRidObject(temp))),
        ]);
      } else {
        setRows([
          ...new Set(temp.concat(...createCustomGRidObject(res.data.content))),
        ]);
      }
    }
  }

  useEffect(() => {
    if (getAllActorsCall) {
      getAllActors();
      handleGetAllActorsCall(false);
    }
  }, [getAllActorsCall, handleGetAllActorsCall]);

  useEffect(() => {
    if (sortClicked.isClicked) {
      if (currentSortDirection === "ASC") {
        if (sortClicked.currentSortValue !== "expiryDate") {
          getAllActors("HeaderSortClick", sortClicked.currentSortValue);
          setCurrentSortDirection("DESC");
        }
      } else if (currentSortDirection === "DESC") {
        if (sortClicked.currentSortValue !== "expiryDate") {
          getAllActors("HeaderSortClick", sortClicked.currentSortValue);
          setCurrentSortDirection("ASC");
        }
      }
    }
  }, [sortClicked]);

  const filterBySelectedValues_AccessDetails = useSelector(
    (state) => state.accessDetails.filterBySelectedValues
  );
  const AccessDetailsfilterByValue = useSelector(
    (state) => state.accessDetails.filterValue
  );

  const getAllAccessDetails = async (changeValue, currentSortValue) => {
    const headers = getAxiosHeaders(true);
    const arr = AccessDetails_Loopfunction(
      filterBySelectedValues_AccessDetails,
      AccessDetailsfilterByValue
    );
    const str = arr.join(",");
    const res = await getService({
      method: "GET",
      url: sortBy_Filter_AccessDetails(
        filterByValue_AccessDetails,
        currentSortValue ? currentSortValue : sortByValue_AccesDetails,
        str,
        currentSortDirection,
        rowsPerPage,
        changeValue === "rowsChange" ? 0 : accessDetailsPage
      ),
      headers: headers,
    });

    if (res && res.data.content) {
      let temp = rows;
      setDataLoadedAccessDetails(true);
      setTotalRows(
        res.data.totalElements
          ? res.data.totalElements
          : res.data.content.length
      );

      let customArr = res.data.content;
      let payload = customArr.map((obj) => {
        return {
          tenantId: obj.tenantId,
          actorId: obj.actorId,
          dataSourceId: obj.dataSourceId,
        };
      });

      if (changeValue === "rowsChange") {
        setRows([...createCustomObject_AccessDetails(res.data.content)]);
      } else if (changeValue === "HeaderSortClick") {
        const DeletedArr = temp.splice(0, rowsPerPage * accessDetailsPage);
        temp = res.data.content;
        setRows([
          ...new Set(
            DeletedArr.concat(...createCustomObject_AccessDetails(temp))
          ),
        ]);
      } else {
        setRows([
          ...new Set(
            temp.concat(createCustomObject_AccessDetails(res.data.content))
          ),
        ]);
      }

      //todo:To make second API call seperately and show percentage of sensitive data accessed
      const sensitiveDataAccessed_Response = await getService({
        method: "POST",
        url: sortBy_Filter_AccessDetails_Percentage(),
        data: { actorSensitivePercentages: payload },
        headers: getAxiosHeaders(true),
      });
      if (sensitiveDataAccessed_Response.status === 200) {
        let outArr =
          createCustomObject_AccessDetails_afterGettingSensitivePercentage(
            sensitiveDataAccessed_Response.data
              ? sensitiveDataAccessed_Response.data
              : [],
            customArr
          );
        if (changeValue === "rowsChange") {
          setRows([...createCustomObject_AccessDetails(outArr)]);
        } else if (changeValue === "HeaderSortClick") {
          const DeletedArr = temp.splice(0, rowsPerPage * accessDetailsPage);
          temp = outArr;
          setRows([
            ...new Set(
              DeletedArr.concat(...createCustomObject_AccessDetails(temp))
            ),
          ]);
        } else {
          setRows([
            ...new Set(temp.concat(createCustomObject_AccessDetails(outArr))),
          ]);
        }
      }
    }
  };
  useEffect(() => {
    if (getAllAccessDetailsCall) {
      getAllAccessDetails();
      handleGetAllAccessDetails(false);
    }
  }, [getAllAccessDetailsCall, handleGetAllAccessDetails]);

  useEffect(() => {
    if (sortClickedAccessDetails.isClicked) {
      if (currentSortDirection === "ASC") {
        getAllAccessDetails(
          "HeaderSortClick",
          sortClickedAccessDetails.currentSortValue
        );
        setCurrentSortDirection("DESC");
      } else if (currentSortDirection === "DESC") {
        getAllAccessDetails(
          "HeaderSortClick",
          sortClickedAccessDetails.currentSortValue
        );
        setCurrentSortDirection("ASC");
      }
    }
  }, [sortClickedAccessDetails]);

  const getAllAccessAPICall = async (changeValue) => {
    const headers = getAxiosHeaders(true);
    const res = await getService({
      method: "GET",
      url: ALL_ACCESS(
        AccessDetailsSideDrawerData.actorId
          ? AccessDetailsSideDrawerData.actorId
          : "",
        AccessDetailsSideDrawerData.dataSourceId,
        rowsPerPage,
        changeValue === "rowsChange" ? 0 : allAccessPage,
        AccessDetailsSideDrawerData.lastAccessedOn
          ? AccessDetailsSideDrawerData.lastAccessedOn
          : ""
      ),
      headers: headers,
      timeout: 1800000,
    });
    if (res && res.data) {
      setLoader(false);
      let temp = rows;
      setTotalRows(
        res.data.totalElements
          ? res.data.totalElements
          : res.data.content.length
      );
      if (changeValue === "rowsChange") {
        const arr = res.data.content.map((item) => {
          return colorFunction(
            item.statementSensitivityInfoDetails,
            item.dbStatement,
            item.timestamp
          );
        });
        setRows([...arr]);
      } else {
        const arr = res.data.content.map((item) => {
          return colorFunction(
            item.statementSensitivityInfoDetails,
            item.dbStatement,
            item.timestamp
          );
        });

        setRows([...new Set(temp.concat(arr))]);
      }
    }
  };

  const getAllAccess_FilePopUpData = async (changeValue) => {
    const headers = getAxiosHeaders(true);
    const res = await getService({
      method: "GET",
      url: ALL_ACCESS_FILEPOPUP(
        nodeId,
        fieldId,
        rowsPerPage,
        changeValue === "rowsChange" ? 0 : allAccessFilePopUpPage,
        lastAccessedTimeStamp,
        nodeSubType.code
      ),
      headers: headers,
    });
    if(res && res.data) {
      setLoader(false);
      let temp = rows;
      setTotalRows(
        res.data.totalElements
          ? res.data.totalElements
          : res.data.content.length
      );
      if (changeValue === "rowsChange") {
        const arr = res.data.content.map((data) => {
          let timestamp = data.timestamp;
          let dbStatement = data.dbStatement;
          let userName = data.userName;
          return {
            timeStamp: timestamp,
            userName: userName,
            dbStatement: dbStatement,
          }
        });
        setRows([...arr]);
      } else {
        const arr = res.data.content.map((data) => {
          let timestamp = data.timestamp;
          let dbStatement = data.dbStatement;
          let userName = data.userName;
          return {
            timeStamp: timestamp,
            userName: userName,
            dbStatement: dbStatement,
          }
        });
        setRows([...new Set(temp.concat(arr))]); 
      }
    }
  };

  const getAllAccessAPICall_serviceDataAcccess = async (changeValue) => {
    const headers = getAxiosHeaders(true);
    const res = await getService({
      method: "GET",
      url: ALL_ACCESS(
        serviceDataAccessDetailsDrawerData.id
          ? serviceDataAccessDetailsDrawerData.id
          : "",
        serviceDataAccessDetailsDrawerData.dataSource
          ? serviceDataAccessDetailsDrawerData.dataSource.id
          : null,
        rowsPerPage,
        changeValue === "rowsChange" ? 0 : allAccessPage_serviceDataAccess,
        serviceDataAccessDetailsDrawerData.lastAccessTime
          ? serviceDataAccessDetailsDrawerData.lastAccessTime
          : "",
        "SERVICE_LEVEL"
      ),
      headers: headers,
    });

    if (res && res.data) {
      setLoader(false);
      let temp = rows;
      setTotalRows(
        res.data.totalElements
          ? res.data.totalElements
          : res.data.content.length
      );
      if (changeValue === "rowsChange") {
        const arr = res.data.content.map((item) => {
          return colorFunction(
            item.statementSensitivityInfoDetails,
            item.dbStatement,
            item.timestamp
          );
        });
        setRows([...arr]);
      } else {
        const arr = res.data.content.map((item) => {
          return colorFunction(
            item.statementSensitivityInfoDetails,
            item.dbStatement,
            item.timestamp
          );
        });

        setRows([...new Set(temp.concat(arr))]);
      }
    }
  };
  useEffect(() => {
    if (getAllAccess) {
      getAllAccessAPICall();
      handleGetAllAccess(false);
    }
  }, [getAllAccess, handleGetAllAccess]);

  useEffect(() => {
    if (getAllAccessFilePopUpData) {
      getAllAccess_FilePopUpData();
      handleGetAllAccessFilePopUp(false)
    }
  }, [getAllAccessFilePopUpData ])

  useEffect(() => {
    if (getAllAccess_ServiceDataAccess) {
      getAllAccessAPICall_serviceDataAcccess();
      handleGetAllAccess_ServiceDataAccess(false);
    }
  }, [getAllAccess_ServiceDataAccess, handleGetAllAccess_ServiceDataAccess]);

  useEffect(() => {
    async function getAllRoles() {
      const headers = getAxiosHeaders(true);
      const res = await getService({
        method: "GET",
        url: ALL_ROLES(
          headers.Tenant ? headers.Tenant : "",
          rowsPerPage,
          rolesPage
        ),
        headers: headers,
      });
      if (res && res.data.content) {
        let temp = rows;
        setTotalRows(res.data.totalElements);
        setRows([...new Set(temp.concat(res.data.content))]);
      }
    }
    if (getAllRolesCall) {
      getAllRoles();
      handleGetAllRolesCall(false);
    }
  }, [getAllRolesCall, handleGetAllRolesCall]);

  const getAllAccessAnomaliesAPICall = async (changeValue) => {
    const headers = getAxiosHeaders(true);
    const res = await getService({
      method: "GET",
      url: ACCESS_ANOMALIES(
        AccessDetailsSideDrawerData.actorId,
        AccessDetailsSideDrawerData.dataSourceId,
        rowsPerPage,
        changeValue === "rowsChange" ? 0 : accessAnomaliesPage
      ),
      headers: headers,
    });
    if (res && res.data.content) {
      setLoader(false);
      let temp = rows;
      setTotalRows(
        res.data.totalElements
          ? res.data.totalElements
          : res.data.content.length
      );
      if (changeValue === "rowsChange") {
        setRows([...res.data.content]);
      } else {
        setRows([...new Set(temp.concat(...res.data.content))]);
      }
    }
  };
  const getAllAccessAnomaliesAPICall_serviceDataAccesses = async (
    changeValue
  ) => {
    const headers = getAxiosHeaders(true);
    const res = await getService({
      method: "GET",
      url: ACCESS_ANOMALIES(
        serviceDataAccessDetailsDrawerData.id,
        serviceDataAccessDetailsDrawerData.dataSource
          ? serviceDataAccessDetailsDrawerData.dataSource.id
          : null,
        rowsPerPage,
        changeValue === "rowsChange" ? 0 : accessAnomaliesPage_serviceDataAccess
      ),
      headers: headers,
    });
    if (res && res.data.content) {
      setLoader(false);
      let temp = rows;
      setTotalRows(
        res.data.totalElements
          ? res.data.totalElements
          : res.data.content.length
      );
      if (changeValue === "rowsChange") {
        setRows([...res.data.content]);
      } else {
        setRows([...new Set(temp.concat(...res.data.content))]);
      }
    }
  };
  useEffect(() => {
    if (getAllAccessAnomalies) {
      getAllAccessAnomaliesAPICall();
      handleGetAllAccessAnomalies(false);
    }
  }, [handleGetAllAccessAnomalies, getAllAccessAnomalies]);
  useEffect(() => {
    if (getAllAccessAnomalies_serviceDataAccesses) {
      getAllAccessAnomaliesAPICall_serviceDataAccesses();
      handleGetAllAccessAnomalies_serviceDataAccesses(false);
    }
  }, [
    handleGetAllAccessAnomalies_serviceDataAccesses,
    getAllAccessAnomalies_serviceDataAccesses,
  ]);

  const getAllDataSourceDetailsAPICall_serviceDataAccesses = async (
    changeValue
  ) => {
    const headers = getAxiosHeaders(true);
    const res = await getService({
      method: "GET",
      url: DATASOURCE_DETAILS(
        serviceDataAccessDetailsDrawerData.id
          ? serviceDataAccessDetailsDrawerData.id
          : null,
        rowsPerPage,
        changeValue === "rowsChange" ? 0 : datasourceDetailsPage_serviceDataAccess

      ),
      headers: headers,
    });
    if (res && res.data.content) {
      setLoader(false);
      let temp = rows;
      setTotalRows(
        res.data.totalElements
          ? res.data.totalElements
          : res.data.content.length
      );
      if (changeValue === "rowsChange") {
        setRows([...res.data.content]);
      } else {
        setRows([...new Set(temp.concat(...res.data.content))]);
      }
    }
  };

 // useeffect should be rendered when we update the value of
 // getAllDataSourceDetails_serviceDataAccesses
  useEffect(() => {
    if (getAllDataSourceDetails_serviceDataAccesses) {
      getAllDataSourceDetailsAPICall_serviceDataAccesses();
      handleGetDataSourceDetails_serviceDataAccesses(false);
    }
  }, [
    getAllDataSourceDetails_serviceDataAccesses,
  ]);


  const getAllUsersAPICall = async (changeValue) => {
    const headers = getAxiosHeaders(true);
    const res = await getService({
      method: "GET",
      url: ALL_USERS(
        headers.Tenant ? headers.Tenant : "",
        rowsPerPage,
        changeValue === "rowsChange" ? 0 : usersPage
      ),
      headers: headers,
    });
    if (res && res.data.content) {
      let temp = rows;
      setTotalRows(
        res.data.totalElements
          ? res.data.totalElements
          : res.data.content.length
      );
      if (changeValue === "rowsChange") {
        setRows([...res.data.content]);
      } else {
        setRows([...new Set(temp.concat(...res.data.content))]);
      }
    }
    // const gridData = (await res) ? (res.data ? res.data.content : []) : [];
    // setgridData([...gridData]);
  };
  useEffect(() => {
    if (getAllUsersCall) {
      getAllUsersAPICall();
      handleGetAllUsersCall(false);
    }
  }, [getAllUsersCall, handleGetAllUsersCall]);

  const getDetailsObject = (changeValue) => {
    if (loc === "sensitiveAccess_serviceDataAccesses") {
      return {
        detailsObj: serviceDataAccessDetailsDrawerData,
        isServiceAccess: "SERVICE_LEVEL",
        requestFormat: {
          actorId: serviceDataAccessDetailsDrawerData.id ? serviceDataAccessDetailsDrawerData.id : "",
          dataSourceId: serviceDataAccessDetailsDrawerData.dataSource
          ? serviceDataAccessDetailsDrawerData.dataSource.id
          : null,
          size: rowsPerPage,
          page: changeValue === "rowsChange" ? 0 : senstiveAccessPage,
          lastAccessedTimeStamp: serviceDataAccessDetailsDrawerData.lastAccessTime ? serviceDataAccessDetailsDrawerData.lastAccessTime : "",
          requestFor: "SERVICE_LEVEL"
        }
      };
    } else {
      return {
        detailsObj: AccessDetailsSideDrawerData,
        isServiceAccess: "",
        requestFormat: {
          actorId: AccessDetailsSideDrawerData.actorId ? AccessDetailsSideDrawerData.actorId : "",
          dataSourceId: AccessDetailsSideDrawerData.dataSourceId,
          size: rowsPerPage,
          page: changeValue === "rowsChange" ? 0 : senstiveAccessPage,
          lastAccessedTimeStamp: AccessDetailsSideDrawerData.lastAccessedOn ? AccessDetailsSideDrawerData.lastAccessedOn : "",
          requestFor: ""
        }
      };
    }
  };

  const getAllSensitiveAccessAPIcall = async (changeValue) => {
    const headers = getAxiosHeaders(true);
    const getDataBasedOnLoc = getDetailsObject(changeValue);
    const { detailsObj, requestFormat }  = getDataBasedOnLoc;
    const res = await getService({
      method: "GET",
      url: SENSITIVE_ACCESS(
        requestFormat.actorId,
        requestFormat.dataSourceId,
        requestFormat.size,
        requestFormat.page,
        requestFormat.lastAccessedTimeStamp,
        requestFormat.requestFor,
      ),
      headers: headers,
      timeout: 1800000,
    });
    if (res && res.data.content) {
      setLoader(false);
      setDataLoadedSenstiveAccess(true);
      let temp = rows;
      setTotalRows(
        res.data.totalElements
          ? res.data.totalElements
          : res.data.content.length
      );
      if (changeValue === "rowsChange") {
        let arr = sensitiveAccessColoringFunction(res.data.content);
        setRows([...arr]);
      } else {
        let arr = sensitiveAccessColoringFunction(res.data.content);
        setRows([...new Set(temp.concat(...arr))]);
      }
    }
  };
  useEffect(() => {
    if (getAllSensitiveInfo) {
      getAllSensitiveAccessAPIcall();
      handleGetSenstiveInfo(false);
    }
  }, [getAllSensitiveInfo, handleGetSenstiveInfo]);

  const getAllSensitiveAccessAPIcall_ServiceDataAccesses = async (
    changeValue
  ) => {
    const headers = getAxiosHeaders(true);
    const res = await getService({
      method: "GET",
      url: SENSITIVE_ACCESS(
        serviceDataAccessDetailsDrawerData.id
          ? serviceDataAccessDetailsDrawerData.id
          : "",
        serviceDataAccessDetailsDrawerData.dataSource
          ? serviceDataAccessDetailsDrawerData.dataSource.id
          : null,
        rowsPerPage,
        changeValue === "rowsChange" ? 0 : senstiveAccessPage_serviceDataAccess,
        serviceDataAccessDetailsDrawerData.lastAccessTime
          ? serviceDataAccessDetailsDrawerData.lastAccessTime
          : "",
        "SERVICE_LEVEL"
      ),
      headers: headers,
    });
    if (res && res.data.content) {
      setDataLoadedSenstiveAccess(true);
      let temp = rows;
      setTotalRows(
        res.data.totalElements
          ? res.data.totalElements
          : res.data.content.length
      );
      if (changeValue === "rowsChange") {
        let arr = sensitiveAccessColoringFunction(res.data.content);
        setRows([...arr]);
      } else {
        let arr = sensitiveAccessColoringFunction(res.data.content);
        setRows([...new Set(temp.concat(...arr))]);
      }
    }
  };
  useEffect(() => {
    if (getAllSensitiveInfo_serviceDataAccess) {
      getAllSensitiveAccessAPIcall_ServiceDataAccesses();
      handleGetSenstiveInfo_serviceDataAccesses(false);
    }
  }, [
    getAllSensitiveInfo_serviceDataAccess,
    handleGetSenstiveInfo_serviceDataAccesses,
  ]);

  useEffect(() => {
    setActorsPage(0);
    async function getAllRoles_RowsChange() {
      const headers = getAxiosHeaders(true);
      const res = await getService({
        method: "GET",
        url: ALL_ROLES(headers.Tenant ? headers.Tenant : "", rowsPerPage, 0),
        headers: headers,
      });
      if (res && res.data.content) {
        let temp = rows;
        setTotalRows(res.data.totalElements);
        setRows([...new Set(temp.concat(res.data.content))]);
      }
    }
    if (loc === "roles" && rowsUpdated) {
      getAllRoles_RowsChange("rowsChange");
      handleGetAllRolesCall(false);
    }
    if (loc === "database_Users" && rowsUpdated) {
      setActorsPage(0);
      setPagesLoaded([`${0}`]);
      setPage(0);
      setDataLoadedDatabaseUsers(false);
      setRows([]);
      setTotalRows(0);
      setTotalElementsDatabaseUsers(0);
      getAllActors("rowsChange");
      handleGetAllActorsCall(false);
    }
    if (loc === "Access_details" && rowsUpdated) {
      setAccessDetailsPage(0);
      setPagesLoaded([`${0}`]);
      setPage(0);
      setDataLoadedAccessDetails(false);
      setRows([]);
      getAllAccessDetails("rowsChange");
      handleGetAllAccessDetails(false);
    }
    if (loc === "All_Access" && rowsUpdated) {
      setLoader(true);
      getAllAccessAPICall("rowsChange");
      handleGetAllAccess(false);
    }
    if (loc === "All_Access_File_PopUp") {
      if (!isEmpty(nodeId) && !isEmpty(fieldId)) {
        setLoader(true);
        getAllAccess_FilePopUpData("rowsChange");
      }
      handleGetAllAccessFilePopUp(false);
    }
    if (loc === "accessAnalomy_serviceDataAccesses" && rowsUpdated) {
      setLoader(true);
      getAllAccessAnomaliesAPICall_serviceDataAccesses("rowsChange");
      handleGetAllAccessAnomalies_serviceDataAccesses(false);
    }
    if (loc === "All_Access_serviceDataAccesses" && rowsUpdated) {
      setLoader(true);
      getAllAccessAPICall_serviceDataAcccess("rowsChange");
      handleGetAllAccess_ServiceDataAccess(false);
    }
    if (loc === "sensitiveAccess_serviceDataAccesses" && rowsUpdated) {
      setLoader(true);
      getAllSensitiveAccessAPIcall("rowsChange");
      handleGetSenstiveInfo_serviceDataAccesses(false);
    }
    if (loc === "datasourceDetails_serviceDataAccesses" && rowsUpdated) {
      setLoader(true);
      getAllDataSourceDetailsAPICall_serviceDataAccesses("rowsChange");
      handleGetDataSourceDetails_serviceDataAccesses(false);
    }
    
    if (loc === "accessAnalomy" && rowsUpdated) {
      getAllAccessAnomaliesAPICall("rowsChange");
      setLoader(true);
      handleGetAllAccessAnomalies(false);
    }
    if (loc === "users" && rowsUpdated) {
      getAllUsersAPICall("rowsChange");
      handleGetAllUsersCall(false);
    }
    if (loc === "sensitiveAccess" && rowsUpdated) {
      setDataLoadedSenstiveAccess(false);
      getAllSensitiveAccessAPIcall("rowsChange");
      handleGetSenstiveInfo(false);
    }
    if (loc === "MDRDashboard_IncidentTable" && rowsUpdated) {
      setRows([]);
      setTotalRows(0);
      setPage(0);
      setPagesLoaded([`${0}`]);
      dispatch({
        type: "UPDATE_INCIDENT_TABLE_QUERY_ASPER_PAGESELECTED",
        payload: 0,
        rowsPerPage: rowsPerPage,
      });
    }
    if (
      (loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
        loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
        loc === "MDRDashboard_AuthenticationTable_UniqueIPs") &&
      rowsUpdated
    ) {
      setRows([]);
      setTotalRows(0);
      setPage(0);
      setPagesLoaded([`${0}`]);
      dispatch({
        type: "CHANGE_MDRAUTHENTICATION_TABLE_QUERY_AS_PER_PAGESELECTED",
        payload: 0,
        rowsPerPage: rowsPerPage,
      });
    }
    if (loc === "MDRDashboard_EndPointsTable" && rowsUpdated) {
      setRows([]);
      setTotalRows(0);
      setPage(0);
      setPagesLoaded([`${0}`]);
      dispatch({
        type: "CHANGE_MDRENDPOINTS_TABLE_QUERY_AS_PER_PAGESELECTED",
        payload: 0,
        rowsPerPage: rowsPerPage,
      });
    }
    if (loc === "MDRDashboard_PatchMonitoring" && rowsUpdated) {
      setRows([]);
      setTotalRows(0);
      setPage(0);
      setPagesLoaded([`${0}`]);
      dispatch({
        type: "CHANGE_MDRPATCHMONITORING_TABLE_QUERY_AS_PER_PAGESELECTED",
        payload: 0,
        rowsPerPage: rowsPerPage,
      });
    }
    setRowsUpdated(false);
  }, [rowsPerPage, rowsUpdated]);

  const IncidenetTableClearAll = useSelector(
    (state) => state.IncidenetTableFilterReducer.clearAll
  );
  const AuthenticationTableClearAll = useSelector(
    (state) => state.MDRAuthenticationReducer.clearAll
  );
  const EndpointsTableClearAll = useSelector(
    (state) => state.MDREndPointsFilterReducer.clearAll
  );

  const PatchMonitoringTableClearAll = useSelector(
    (state) => state.MDRPatchMonitoringReducer.clearAll
  );

  useEffect(() => {
    if (
      IncidenetTableClearAll ||
      AuthenticationTableClearAll ||
      EndpointsTableClearAll ||
      PatchMonitoringTableClearAll
    ) {
      setRows([]);
      setTotalRows(0);
      setPage(0);
      setPagesLoaded([`${0}`]);
    }
  }, [
    IncidenetTableClearAll,
    AuthenticationTableClearAll,
    EndpointsTableClearAll,
    PatchMonitoringTableClearAll,
  ]);

  useEffect(() => {
    async function getAllPoliciesAfterUpdate() {
      const response = await getAllAlertPolicies(page, rowsPerPage);
      if (response && response.content) {
        setTotalRows(response.totalElements);
        setRows(response.content);
        setLoader(false);
        //put error code here
      } else {
        setRows([]);
      }
    }
    if (isDeleted || policyCreated || policyStatusUpdated || policyUpdated) {
      setRows([]);
      setLoader(true);
      getAllPoliciesAfterUpdate();
    }
  }, [
    isDeleted,
    policyCreated,
    policyStatusUpdated,
    policyUpdated,
    handleGetAllPoliciesCall,
  ]);
  //get all alerts api call, divide it into resolved and unresolved, make the unique policies array
  useEffect(() => {
    async function filterAction(
      severity,
      resolution,
      policy,
      sortBy,
      alertPage
    ) {
      const response = await filterAlerts(
        severity,
        resolution,
        policy,
        sortBy,
        alertPage,
        rowsPerPage
      );
      if (response && response.content) {
        let temp = rows;
        setTotalRows(response.totalElements);
        setRows(response.content);
        handleGetAllAlertsCall(false);
        setLoader(false);
      }
    }
    async function getAllAlerts() {
      const response = await fetchAllAlerts(alertPage, rowsPerPage);
      if (response && response.content) {
        let temp = rows;
        setTotalRows(response.totalElements);
        setRows(response.content);
        handleGetAllAlertsCall(false);
        setLoader(false);
      }
    }
    if (getAllAlertsCall) {
      setLoader(true);
      if (
        severityFilter.length > 0 ||
        resolutionFilter.length > 0 ||
        policyFilter.length > 0 ||
        sortBy !== "" ||
        (severityFilter.length > 0 &&
          resolutionFilter.length > 0 &&
          policyFilter.length > 0 &&
          sortBy !== "")
      ) {
        filterAction(
          severityFilter,
          resolutionFilter,
          policyFilter,
          sortBy,
          alertPage
        );
      } else {
        getAllAlerts();
      }
    }
    return () => {
      setSelected([]);
      dispatch({ type: "SELECTED_ITEMS", selectedArray: [] });
    };
  }, [getAllAlertsCall, handleGetAllAlertsCall]);

  //get all policy-alerts api call, divide it into resolved and unresolved, make the unique policies array
  useEffect(() => {
    async function filterAction(
      severity,
      resolution,
      policy,
      sortBy,
      alertPage
    ) {
      const response = await filterAlerts(
        severity,
        resolution,
        policy,
        sortBy,
        alertPage,
        rowsPerPage,
        policyId
      );
      if (response && response.content) {
        let temp = rows;
        setTotalRows(response.totalElements);
        setRows(response.content);
        handleGetPolicyAlerts(false);
        setLoader(false);
      }
    }

    // get alert policies by id
    async function getAllPolicyAlerts() {
      const response = await getAlertPolicyAlerts(
        policyId,
        alertPage,
        rowsPerPage
      );
      if (response && response.content) {
        let temp = rows;
        setTotalRows(response.totalElements);
        setRows(response.content);
        handleGetPolicyAlerts(false);
        setLoader(false);
      }
    }
    if (getPolicyAlerts) {
      setLoader(true);
      if (
        severityFilter.length > 0 ||
        resolutionFilter.length > 0 ||
        policyFilter.length > 0 ||
        sortBy !== "" ||
        (severityFilter.length > 0 &&
          resolutionFilter.length > 0 &&
          policyFilter.length > 0 &&
          sortBy !== "")
      ) {
        filterAction(
          severityFilter,
          resolutionFilter,
          policyFilter,
          sortBy,
          alertPage,
          policyId
        );
      } else {
        getAllPolicyAlerts();
      }
    }
    return () => {
      setSelected([]);
      dispatch({ type: "SELECTED_ITEMS", selectedArray: [] });
    };
  }, [getPolicyAlerts]);

  //

  // window.onload = (event) => {
  //   if (
  //     loc === "MDRDashboard_IncidentTable" ||
  //     loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
  //     loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
  //     loc === "MDRDashboard_AuthenticationTable_UniqueIPs" ||
  //     loc === "MDRDashboard_EndPointsTable" ||
  //     loc === "MDRDashboard_PatchMonitoring"
  //   ) {
  //     console.log("onLoad", "onLoad");
  //     setRows([]);
  //   }
  // };

  const currentStatus = useSelector(
    (state) => state.IncidenetTableFilterReducer.currentStatus
  );
  const incidentTableQuery = useSelector(
    (state) => state.IncidenetTableFilterReducer.tableQuery
  );

  const currentStatusMDRAuthenticationTable = useSelector(
    (state) => state.MDRAuthenticationReducer.currentStatus
  );
  const authenticationTableQuery = useSelector(
    (state) => state.MDRAuthenticationReducer.authenticationTableQuery
  );

  const currentStatusEndpointsTable = useSelector(
    (state) => state.MDREndPointsFilterReducer.currentStatus
  );
  const endPointsTableQuery = useSelector(
    (state) => state.MDREndPointsFilterReducer.endPointsTableQuery
  );

  const currentStatusPatchmonitoringTable = useSelector(
    (state) => state.MDRPatchMonitoringReducer.currentStatus
  );
  const patchMonitoringTableQuery = useSelector(
    (state) => state.MDRPatchMonitoringReducer.patchMonitoringTableQuery
  );
  useEffect(() => {
    return () => {
      // clearing the current status
      const currentRoute = window.location.pathname;
      if (currentRoute !== "/mdr/MDRdashboards") {
        dispatch({
          type: CLEAR_CURRENT_STATUS,
        });
      }
    };
  }, []);
  //Clean up the tabledata if filter is applied
  useEffect(() => {
    let MdrTablecurrentStatus = MDRTableCurrentStatusFinder(
      loc,
      currentStatus,
      currentStatusMDRAuthenticationTable,
      currentStatusEndpointsTable,
      currentStatusPatchmonitoringTable
    );
    if (
      MdrTablecurrentStatus === "apply" ||
      MdrTablecurrentStatus === "dateRangeChanged"
    ) {
      setRows([]);
      setTotalRows(0);
      setPage(0);
      setPagesLoaded([`${0}`]);
    }
  }, [
    currentStatus,
    incidentTableQuery,
    currentStatusMDRAuthenticationTable,
    authenticationTableQuery,
    endPointsTableQuery,
    currentStatusEndpointsTable,
    patchMonitoringTableQuery,
    currentStatusPatchmonitoringTable,
  ]);

  //if table data is being sent from any component
  useEffect(() => {
    if (tableData && tableData.length > 0) {
      let temp = rows;

      if (totalPolicyAlerts > 0) {
        setTotalRows(totalPolicyAlerts);
      } else if (totalElements > 0) {
        setTotalRows(totalElements);
      } else {
        setTotalRows(tableData.length);
      }
      if (
        loc === "MDRDashboard_IncidentTable" ||
        loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
        loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
        loc === "MDRDashboard_AuthenticationTable_UniqueIPs" ||
        loc === "MDRDashboard_EndPointsTable" ||
        loc === "MDRDashboard_PatchMonitoring"
      ) {
        let currentTableStatus = MDRTableCurrentStatusFinder(
          loc,
          currentStatus,
          currentStatusMDRAuthenticationTable,
          currentStatusEndpointsTable,
          currentStatusPatchmonitoringTable
        );
        if (
          currentTableStatus &&
          currentTableStatus !== "searchValue" &&
          currentTableStatus !== "dateRangeChanged"
        ) {
          setRows([...temp, ...tableData]);
        } else {
          setRows([...tableData]);
        }
      } else {
        setRows([...tableData]);
      }

      //To fix the pagination error in Incident Table
      // setRows([...tableData]);
      //Will set the selected items to zero after delete
      setSelected([]);
    } else if (tableData) {
      if (
        (tableData.length === 0 && loc === "database_Users") ||
        loc === "Access_details" ||
        loc === "show-policy-flow-groups" ||
        loc === "sql-statements" ||
        loc === "span-sql-statements" ||
        loc === "OneDrive"
      ) {
        setRows([]);
        setTotalRows(0);
      } else if (
        (!tableData.length && loc === "database_Users") ||
        loc === "Access_details" ||
        loc === "MDRDashboard_IncidentTable" ||
        loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
        loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
        loc === "MDRDashboard_AuthenticationTable_UniqueIPs" ||
        loc === "MDRDashboard_EndPointsTable" ||
        loc === "MDRDashboard_PatchMonitoring"
      ) {
        setRows([]);
        setTotalRows(0);
        setPage(0);
        setPagesLoaded([`${0}`]);
      }
    }
  }, [tableData, totalPolicyAlerts, totalElements]);

  const incidentSearch = useSelector(
    (state) => state.IncidenetTableFilterReducer.incidentSearch
  );
  useEffect(() => {
    if (incidentSearch) {
      setRows([]);
      setTotalRows(0);
      setPage(0);
      setPagesLoaded([`${0}`]);
    }
  }, [incidentSearch]);

  useEffect(() => {
    if (
      loc === "MDRDashboard_IncidentTable" ||
      loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
      loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
      loc === "MDRDashboard_AuthenticationTable_UniqueIPs" ||
      loc === "MDRDashboard_EndPointsTable" ||
      loc === "MDRDashboard_PatchMonitoring"
    )
      if (tableData.length > 0) {
        setRows([]);
        setTotalRows(0);
        setPage(0);
        setPagesLoaded([`${0}`]);
      }
  }, [loc]);

  //setting all the ids in reducer for directing to datamap

  useEffect(() => {
    if (
      loc !== "database_Users" &&
      loc !== "MDRDashboard_IncidentTable" &&
      loc !== "MDRDashboard_AuthenticationTable_AllUsers" &&
      loc !== "MDRDashboard_AuthenticationTable_UniqueUsers" &&
      loc !== "MDRDashboard_AuthenticationTable_UniqueIPs" &&
      loc !== "MDRDashboard_EndPointsTable" &&
      loc !== "MDRDashboard_PatchMonitoring"
    ) {
      if (selected.length === 0 && rows.length > 0) {
        const newSelecteds = rows.map((n) => n.id);
        dispatch({ type: "SELECTED_ITEMS", selectedArray: newSelecteds });
      }
    }
  }, [selected, rows]);

  //filter function
  useEffect(() => {
    async function filterAction(severity, resolution, policy, sortBy) {
      const response = await filterAlerts(
        severity,
        resolution,
        policy,
        sortBy,
        0,
        rowsPerPage,
        policyId
      );
      if (response && response.content) {
        setTotalRows(response.totalElements);
        setRows(response.content);
        setPage(0);
        setAlertPage(0);
        setPagesLoaded([`${0}`]);
      }
    }
    async function sortPolicies(sortBy) {
      const response = await sortAlertsPolicies(sortBy, page, rowsPerPage);
      if (response && response.content) {
        setTotalRows(response.totalElements);
        setRows(response.content);
      }
    }
    if (
      severityFilter.length > 0 ||
      resolutionFilter.length > 0 ||
      policyFilter.length > 0 ||
      sortBy !== "" ||
      (severityFilter.length > 0 &&
        resolutionFilter.length > 0 &&
        policyFilter.length > 0 &&
        sortBy !== "")
    ) {
      if (loc === "policy") {
        sortPolicies(sortBy);
      } else if (loc === "alerts" || loc === "summary") {
        filterAction(severityFilter, resolutionFilter, policyFilter, sortBy);
      }
    }
  }, [severityFilter, resolutionFilter, policyFilter, sortBy, loc]);

  //this method is used in the sort and filter component
  const handleCheckbox = (event, type) => {
    if (event.target.checked) {
      if (type === "severity") {
        if (!severityFilter.includes(event.target.value)) {
          setSeverityFilter([...severityFilter, event.target.value]);
        }
      } else if (type === "resolutionType") {
        if (!resolutionFilter.includes(event.target.value)) {
          setResolutionFilter([...resolutionFilter, event.target.value]);
        }
      } else if (type === "policy") {
        if (!policyFilter.includes(event.target.value)) {
          setPolicyFilter([...policyFilter, event.target.value]);
        }
      }
    } else {
      if (type === "severity") {
        let temp = severityFilter.filter((each) => each !== event.target.value);
        setSeverityFilter(temp);
      } else if (type === "resolutionType") {
        let temp = resolutionFilter.filter(
          (each) => each !== event.target.value
        );
        setResolutionFilter(temp);
      } else if (type === "policy") {
        let temp = policyFilter.filter((each) => each !== event.target.value);
        setPolicyFilter(temp);
      }
    }
  };

  const handleSortingFilter = (event) => {
    setSortBy(event.target.value);
  };

  const clearFilterButtonClick = () => {
    if (loc === "alerts" || loc === "summary") {
      setPage(0);
      setAlertPage(0);
      setRows([]);
      setPagesLoaded([`${0}`]);
      if (policyId) {
        handleGetPolicyAlerts(true);
      } else {
        handleGetAllAlertsCall(true);
      }
    }
    if (loc === "policy") {
      handleGetAllPoliciesCall(true);
    }
    setSeverityFilter([]);
    setResolutionFilter([]);
    setPolicyFilter([]);
    setSortBy("");
  };

  const toggleDrawer = (open, event, alertId) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSelectedAlertId(alertId);
    setStateOpen(open);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  console.log(order, orderBy);

  const handleSelectAllClick = (event) => {
    let newSelecteds;
    if (event.target.checked) {
      if (loc === "database_Users") {
        newSelecteds = getRows().map((n) => n.databaseAccessRequestID);
      } else if (loc === "policy") {
        // when status is  Unresolved and monitors length > 0
        newSelecteds = [];
        getRows().forEach((row) => {
          if (row && row.status && row.status.code !== "DELETED") {
            newSelecteds.push(row.id);
          }
        });
      } else {
        newSelecteds = getRows().map((n) => n.id);
      }
      setSelected(newSelecteds);
      dispatch({ type: "SELECTED_ITEMS", selectedArray: newSelecteds });
    } else {
      dispatch({ type: "SELECTED_ITEMS", selectedArray: [] });
      setSelected([]);
    }
  };

  const handleOpenSeverityPopUp = (newPlacement, row) => (event) => {
    setSelectedRowForSeverityChange(row.id);
    setAnchorEl(event.currentTarget);
    setSeverityPopUpOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const handleCloseSeverityPopup = () => {
    setAnchorEl(null);
    setSeverityPopUpOpen(false);
  };

  const handleClick = (event, id, name) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if ((name !== "") & (name !== undefined) && name !== null) {
      setSelectedTenantForDelete(name);
    }
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    dispatch({ type: "SELECTED_ITEMS", selectedArray: newSelected });
  };
  const handleChangePage = (event, newPage) => {
    if (loc === "policy") {
      setPage(newPage);
      return;
    } else if (loc === "alerts" || loc === "summary") {
      setPage(newPage);
      setAlertPage(newPage);
      if (policyId && handleGetPolicyAlerts) {
        handleGetPolicyAlerts(true);
      } else if (handleGetAllAlertsCall) {
        handleGetAllAlertsCall(true);
      }
      return;
    }
    if (pagesLoaded.length > 0) {
      if (pagesLoaded.indexOf(`${newPage}`) === -1) {
        setPagesLoaded([...new Set(pagesLoaded.concat(`${newPage}`))]);
        if (rows.length !== totalRows) {
          if (resolvedAlerts.length + rows.length !== totalRows) {
            if (
              handleGetAllAlertsCall !== undefined ||
              handleGetPolicyAlerts !== undefined
            ) {
              if (newPage < page) {
                setPage(newPage);
              } else {
                setPage(newPage);
                setAlertPage(newPage);
                if (policyId) {
                  handleGetPolicyAlerts(true);
                } else {
                  handleGetAllAlertsCall(true);
                }
              }
            }
          }
          if (loc === "MDRDashboard_IncidentTable") {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              dispatch({
                type: "UPDATE_INCIDENT_TABLE_QUERY_ASPER_PAGESELECTED",
                payload: newPage,
                rowsPerPage: rowsPerPage,
              });
            }
          }
          if (
            loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
            loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
            loc === "MDRDashboard_AuthenticationTable_UniqueIPs"
          ) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              dispatch({
                type: "CHANGE_MDRAUTHENTICATION_TABLE_QUERY_AS_PER_PAGESELECTED",
                payload: newPage,
                rowsPerPage: rowsPerPage,
              });
            }
          }
          if (loc === "MDRDashboard_EndPointsTable") {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              dispatch({
                type: "CHANGE_MDRENDPOINTS_TABLE_QUERY_AS_PER_PAGESELECTED",
                payload: newPage,
                rowsPerPage: rowsPerPage,
              });
            }
          }
          if (loc === "MDRDashboard_PatchMonitoring") {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              dispatch({
                type: "CHANGE_MDRPATCHMONITORING_TABLE_QUERY_AS_PER_PAGESELECTED",
                payload: newPage,
                rowsPerPage: rowsPerPage,
              });
            }
          }
          //MDRDashboard_PatchMonitoring
          // MDRDashboard_EndPointsTable
          if (handleGetAllRolesCall !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              setRolesPage(newPage);
              handleGetAllRolesCall(true);
            }
          }

          if (handleGetAllActorsCall !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              // alert(newPage, page);
              setPage(newPage);
              setActorsPage(newPage);
              handleGetAllActorsCall(true);
            }
          }
          if (handleGetAllAccessDetails !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              setAccessDetailsPage(newPage);
              handleGetAllAccessDetails(true);
            }
          }
          if (handleGetAllAccess !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              setAllAccessPage(newPage);
              handleGetAllAccess(true);
            }
          }
          if (handleGetAllAccessFilePopUp !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              setAllAccessFilePopUpPage(newPage);
              handleGetAllAccessFilePopUp(true);
            }
          }
          if (handleGetAllAccess_ServiceDataAccess !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              setAllAccessPage_serviceDataAccess(newPage);
              handleGetAllAccess_ServiceDataAccess(true);
            }
          }
          if (handleGetAllAccessAnomalies_serviceDataAccesses !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              setAccessAnomaliesPage_serviceDataAccess(newPage);
              handleGetAllAccessAnomalies_serviceDataAccesses(true);
            }
          }
          if (handleGetDataSourceDetails_serviceDataAccesses !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              setdatasourceDetailsPage_serviceDataAccess(newPage);
              handleGetDataSourceDetails_serviceDataAccesses(true);
            }
          }

          if (handleGetAllAccessAnomalies !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              setAccessAnomaliesPage(newPage);
              handleGetAllAccessAnomalies(true);
            }
          }
          if (handleGetSenstiveInfo !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              setSenstiveAccessPage(newPage);
              handleGetSenstiveInfo(true);
            }
          }

          if (handleGetSenstiveInfo_serviceDataAccesses !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              setSenstiveAccessPage_serviceDataAccess(newPage);
              handleGetSenstiveInfo_serviceDataAccesses(true);
            }
          }

          if (handleGetAllUsersCall !== undefined) {
            if (newPage < page) {
              setPage(newPage);
            } else {
              setPage(newPage);
              setUsersPage(newPage);
              handleGetAllUsersCall(true);
            }
          }

          if (handleGetAllPoliciesCall !== undefined) {
            setPage(newPage);
            handleGetAllPoliciesCall(false);
          }
          // if (handleGetPolicyAlerts !== undefined) {
          //   setPage(newPage);
          //   handleGetPolicyAlerts(true, page);
          // }
        } else {
          setPage(newPage);
          setActorsPage(newPage);
          setAccessDetailsPage(newPage);
        }
      } else {
        setPage(newPage);
        setActorsPage(newPage);
        setAccessDetailsPage(newPage);
      }
    }
    if (selected.length !== 0) {
      dispatch({ type: "SELECTED_ITEMS", selectedArray: [] });
      setSelected([]);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsperPage", parseInt(event.target.value, 10));
    if (loc === "policy") {
      setPage(0);
    }
    if (loc === "alerts" || loc === "summary") {
      setPage(0);
      setAlertPage(0);
      if (policyId) {
        getAlertPolicyOnPageChange(parseInt(event.target.value, 10));
      } else {
        getAlertsOnPageChange(parseInt(event.target.value, 10));
      }
    }
    if (loc === "sensitiveAccess") {
      setRows([]);
      setRowsUpdated(true);
      setPage(0);
      setPagesLoaded([`${0}`]);
      setSenstiveAccessPage(0);
    }
    if (loc === "sensitiveAccess_serviceDataAccesses") {
      setRows([]);
      setRowsUpdated(true);
      setPage(0);
      setPagesLoaded([`${0}`]);
      setSenstiveAccessPage(0);
      setSenstiveAccessPage_serviceDataAccess(0);
    }
    if (
      loc === "accessAnalomy" ||
      loc === "All_Access" ||
      loc === "All_Access_File_PopUp" ||
      loc === "accessAnalomy_serviceDataAccesses" ||
      loc === "All_Access_serviceDataAccesses" ||
      loc === "datasourceDetails_serviceDataAccesses" ||
      loc === "users" ||
      loc === "roles"
    ) {
      setPage(0);
      setRowsUpdated(true);
      setAccessAnomaliesPage(0);
      setAllAccessPage(0);
      setAllAccessFilePopUpPage(0);
      setAccessAnomaliesPage_serviceDataAccess(0);
      setAllAccessPage_serviceDataAccess(0);
      setdatasourceDetailsPage_serviceDataAccess(0);
      setRolesPage(0);
      setUsersPage(0);
      setPagesLoaded([`${0}`]);
      setRows([]);
    }

    if (loc === "database_Users" || loc === "Access_details") {
			setRowsUpdated(true);
		}
		
		if (loc === "sql-statements" || loc === "span-sql-statements" || loc === "OneDrive") {
			setPage(0);
		}

    if (selected.length !== 0) {
      dispatch({ type: "SELECTED_ITEMS", selectedArray: [] });
      setSelected([]);
    }

    if (
      loc === "MDRDashboard_IncidentTable" ||
      loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
      loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
      loc === "MDRDashboard_AuthenticationTable_UniqueIPs" ||
      loc === "MDRDashboard_EndPointsTable" ||
      loc === "MDRDashboard_PatchMonitoring"
    ) {
      setRowsUpdated(true);
    }
    // setRowsUpdated(true);
    // setpaginationRows(parseInt(event.target.value, 10));
  };
  const needs = {
    "Access_details": [VIEW_ENTERPRISE_ACTORS],
    "accessAnalomy": [viewDatabaseLogs],
    "accessAnalomy_serviceDataAccesses": [viewDatabaseLogs],
    "MDRDashboard_IncidentTable":[],
    "MDRDashboard_PatchMonitoring":[],
    "MDRDashboard_EndPointsTable":[],
    "MDRDashboard_AuthenticationTable_UniqueUsers":[],
    "MDRDashboard_AuthenticationTable_AllUsers":[],
    "MDRDashboard_AuthenticationTable_UniqueIPs":[],
  }
  const tableRowsClickHandler = (row) => {
    if (loc === "Access_details") {
      dispatch(openAccess_Drawer(row));
    }
    if (loc === "accessAnalomy") {
      dispatch(closeAccess_Drawer());
      history.push({
        pathname: "/tenant/databaseLogs",
        state: {
          alertId: row.alertId,
          configuration: row.configuration,
          tag: "directDbLogs",
        },
      });
    }
    if (loc === "accessAnalomy_serviceDataAccesses") {
      dispatch(closeAccess_Drawer());
      history.push({
        pathname: "/tenant/databaseLogs",
        state: {
          alertId: row.alertId,
          configuration: row.configuration,
          tag: "serviceLogs",
        },
      });
    }
    if (loc === "MDRDashboard_IncidentTable") {
      dispatch({ type: "INCIDENT_DRAWER_DATA", payload: row });
    }
    if (loc === "MDRDashboard_PatchMonitoring") {
      dispatch({ type: "PATCH_MONITORING_DRAWER_DATA", payload: row });
    }
    if (loc === "MDRDashboard_EndPointsTable") {
      dispatch({ type: "END_POINT_DRAWER_DATA", payload: row });
    }
    if (loc === "MDRDashboard_AuthenticationTable_UniqueUsers") {
      dispatch({ type: "AUTHENTICATION_DRAWER_DATA", payload: row });
    }
    if (loc === "MDRDashboard_AuthenticationTable_AllUsers") {
      dispatch({ type: "AUTHENTICATION_DRAWER_DATA", payload: row });
    }
    if (loc === "MDRDashboard_AuthenticationTable_UniqueIPs") {
      dispatch({ type: "AUTHENTICATION_DRAWER_DATA", payload: row });
    }
  };

  const getAlertsOnPageChange = (rowsPerPage) => {
    async function filterAction(severity, resolution, policy, sortBy) {
      const response = await filterAlerts(
        severity,
        resolution,
        policy,
        sortBy,
        0,
        rowsPerPage,
        policyId
      );
      if (response && response.content) {
        setTotalRows(response.totalElements);
        setRows(response.content);
        setLoader(false);
      }
    }
    async function getAllAlerts() {
      const response = await fetchAllAlerts(0, rowsPerPage);
      if (response && response.content) {
        setTotalRows(response.totalElements);
        setRows(response.content);
        setLoader(false);
      }
    }
    setLoader(true);
    setPagesLoaded([`${0}`]);
    if (
      severityFilter.length > 0 ||
      resolutionFilter.length > 0 ||
      policyFilter.length > 0 ||
      sortBy !== "" ||
      (severityFilter.length > 0 &&
        resolutionFilter.length > 0 &&
        policyFilter.length > 0 &&
        sortBy !== "")
    ) {
      filterAction(severityFilter, resolutionFilter, policyFilter, sortBy);
    } else {
      getAllAlerts();
    }
  };

  // on Rows change of alerts by id
  const getAlertPolicyOnPageChange = (rowsPerPage) => {
    async function filterAction(severity, resolution, policy, sortBy) {
      const response = await filterAlerts(
        severity,
        resolution,
        policy,
        sortBy,
        0,
        rowsPerPage
      );
      if (response && response.content) {
        setTotalRows(response.totalElements);
        setRows(response.content);
        setLoader(false);
      }
    }

    async function getAllPolicyAlerts() {
      const response = await getAlertPolicyAlerts(policyId, 0, rowsPerPage);
      if (response && response.content) {
        setTotalRows(response.totalElements);
        setRows(response.content);
        setLoader(false);
      }
    }
    setLoader(true);
    setPagesLoaded([`${0}`]);
    if (
      severityFilter.length > 0 ||
      resolutionFilter.length > 0 ||
      policyFilter.length > 0 ||
      sortBy !== "" ||
      (severityFilter.length > 0 &&
        resolutionFilter.length > 0 &&
        policyFilter.length > 0 &&
        sortBy !== "")
    ) {
      filterAction(severityFilter, resolutionFilter, policyFilter, sortBy);
    } else {
      getAllPolicyAlerts();
    }
  };

  useEffect(() => {
    if (loc === "policy") {
      handleGetAllPoliciesCall(true);
    }
  }, [page]);

  useEffect(() => {
    if (loc === "policy") {
      handleGetAllPoliciesCall(true);
    }
  }, [rowsPerPage]);
  // useEffect(() => {
  //   document.body.addEventListener("click", (event) => {
  //     let attachEvent;
  //     const classNamesArr = event.target.className.split(" ");
  //     classNamesArr.forEach((el) => {
  //       if (el === "accessDetailsRow") {
  //         dispatch(openAccess_Drawer());
  //       }
  //     });
  //   });
  // });

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const classes = useStyles();
  const d = new Date();
  let diff = d.getTimezoneOffset();

  const getRows = () => {
    // getting row data according to current page
    if (loc === "policy") {
      return stableSort(rows, getComparator(order, orderBy));
    } else if (loc === "alerts" || loc === "summary") {
      return stableSort(rows, getComparator(order, orderBy));
    } else {
      return stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }
  };

  const actualCount = (rowData) => {
    //As totalRows is used by many components we are applying the below logic only if the location is All Access
    if (loc === "All_Access" && isClick) {
      return allAccessTotalCount;
    } else {
      return rowData;
    }

  };

  //To get total number of rows
  const getCount = () => {
    let count = 0;
    if ( totalRows !== null && totalRows !== undefined ) {
      count = actualCount(totalRows);
    }
    return count; 
  }
  const renderTableCells = (row, isItemSelected, status, index, labelId) => {
    return (
      <>
        {loc === "alerts" || loc === "summary" ? (
          <>
            <TableCell padding="checkbox">
              <Checkbox
                className="checkbox-root"
                disableRipple
                color="default"
                onClick={(event) => {
                  handleClick(event, row.id);
                }}
                checkedIcon={
                  isItemSelected ? (
                    <span className="checked-icon" />
                  ) : (
                    <span className="checkbox-icon" />
                  )
                }
                icon={<span className="checkbox-icon" />}
                inputProps={{
                  "aria-label": "decorative checkbox",
                }}
                checked={isItemSelected}
              />
            </TableCell>
            <TableCell
              onClick={(event) => {
                if (loc === "alerts") {
                  toggleDrawer(true, event, row.id);
                }
              }}
              align="center"
              component="th"
              id={labelId}
              scope="row"
            >
              <LevelComponent
                level={
                  row.severity.value
                    ? row.severity.value.toUpperCase()
                    : row.severity.toUpperCase()
                }
                Id={labelId}
              />
            </TableCell>
            {/* <TableCell
                                  onClick={(event) => {
                                    toggleDrawer(true, event, row.id);
                                  }}
                                  align="left"
                                >
                                  <LevelComponent
                                    level={
                                      row.severity.value
                                        ? row.severity.value.toUpperCase()
                                        : row.severity.toUpperCase()
                                    }
                                    status={""}
                                    risk={
                                      row.riskScore === 0 ? "0" : row.riskScore
                                    }
                                    Id={labelId}
                                  />
                                </TableCell>
                                <TableCell
                                  onClick={(event) => {
                                    toggleDrawer(true, event, row.id);
                                  }}
                                  align="left"
                                >
                                  {getTime(row.generatedOn)}
                                </TableCell>
                                <TableCell
                                  onClick={(event) => {
                                    if (loc === "alerts") {
                                      dispatch({
                                        type: "TRACK_TRAVERSAL",
                                        payload: `Alert Policies - ${row.alertPolicy.name}`,
                                      });
                                      history.push(
                                        `/tenant/alertPolicies/${row.alertPolicy.id}`
                                      );
                                    }
                                  }}
                                  align="left"
                                  style={{ color: "#081981" }}
                                >
                                  {row.alertPolicy.name}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ color: "#081981" }}
                                >
                                  {!row.automatedMonitoring
                                    ? row.trigger &&
                                      row.trigger.triggerCondition
                                      ? `${
                                          row.trigger.triggerCondition
                                            .comparisonType.value
                                        } by ${
                                          row.trigger.triggerCondition.value
                                        } ${
                                          row.trigger.triggerCondition
                                            .aggregationType.value ===
                                          "Percentage"
                                            ? "%"
                                            : ""
                                        } on a ${
                                          row.trigger.triggerCondition
                                            .timeWindowType.value
                                        } basis.`
                                      : "User Unauthorized Access"
                                    : `Auto Monitored`}
                                </TableCell>
                                <TableCell align="left">
                                  <AvatarGroup className="avatar-root" max={4}>
                                    {row.monitors && row.monitors !== null
                                      ? row.monitors.map((each, index) => {
                                </TableCell> */}
            <TableCell
              onClick={(event) => {
                toggleDrawer(true, event, row.id);
              }}
              align="left"
            >
              {getTime(row.generatedOn)}
            </TableCell>
            <TableCell
              onClick={(event) => {
                if (loc === "alerts") {
                  dispatch({
                    type: "TRACK_TRAVERSAL",
                    payload: `Alert Policies - ${row.alertPolicy.name}`,
                  });
                  history.push(`/tenant/alertPolicies/${row.alertPolicy.id}`);
                }
              }}
              align="left"
              style={{ color: "#081981" }}
            >
              {row.alertPolicy.name}
            </TableCell>
            <TableCell align="left" style={{ color: "#081981" }}>
              {row.type ? row.type : ""}
              {/* {!row.automatedMonitoring
                                  ? row.trigger && row.trigger.triggerCondition
                                    ? `${
                                        row.trigger.triggerCondition
                                          .comparisonType.value
                                      } by ${
                                        row.trigger.triggerCondition.value
                                      } ${
                                        row.trigger.triggerCondition
                                          .aggregationType.value ===
                                        "Percentage"
                                          ? "%"
                                          : ""
                                      } on a ${
                                        row.trigger.triggerCondition
                                          .timeWindowType.value
                                      } basis.`
                                    : "User Unauthorized Access"
                                  : `Auto Monitored`} */}
            </TableCell>
            <TableCell
              style={{ color: "#081981", cursor: "pointer" }}
              align="left"
            >
              {
                <span>
                  {(row.dataSource && row.dataSource.aliasName) || ""}{" "}
                </span>
              }
            </TableCell>
            <TableCell
              style={{ color: "#081981", cursor: "pointer" }}
              align="left"
            >
              {
                <span>
                  {(row.enterpriseActor && row.enterpriseActor.username) || ""}{" "}
                </span>
              }
            </TableCell>
            <TableCell align="left">
              <AvatarGroup className="avatar-root" max={4}>
                {row.monitors && row.monitors !== null
                  ? row.monitors.map((each, index) => {
                      let temp = each.monitorThumbnail;
                      return (
                        !each.muted && (
                          <Tooltip title={temp.name}>
                            <Avatar
                              className="avatar"
                              id={index}
                              alt={temp.name}
                              elevation={4}
                              src={`data:image/png;base64, ${temp.picture}`}
                            />
                          </Tooltip>
                        )
                      );
                    })
                  : row.alertPolicy && row.alertPolicy.monitors !== null
                  ? row.alertPolicy.monitors.map((each, index) => {
                      let temp = each.monitorThumbnail;
                      return (
                        !each.muted && (
                          <Tooltip title={temp.name}>
                            <Avatar
                              className="avatar"
                              id={index}
                              alt={temp.name}
                              elevation={4}
                              src={`data:image/png;base64, ${temp.picture}`}
                            />
                          </Tooltip>
                        )
                      );
                    })
                  : row.alertPolicy && row.alertPolicy.monitors !== null
                  ? row.alertPolicy.monitors.map((each, index) => {
                      let temp = each.monitorThumbnail;
                      return (
                        !each.muted && (
                          <Tooltip title={temp.name}>
                            <Avatar
                              className="avatar"
                              id={index}
                              alt={temp.name}
                              elevation={4}
                              src={temp.picture}
                            />
                          </Tooltip>
                        )
                      );
                    })
                  : ""}
              </AvatarGroup>
            </TableCell>
          </>
        ) : loc === "policy" ? (
          <>
            <TableCell padding="checkbox">
              <Checkbox
                className="checkbox-root"
                disableRipple
                color="default"
                onClick={(event) => {
                  handleClick(event, row.id);
                }}
                disabled={row && row.status && row.status.code === "DELETED"}
                checkedIcon={
                  isItemSelected ? (
                    <span className="checked-icon" />
                  ) : (
                    <span className="checkbox-icon" />
                  )
                }
                icon={<span className="checkbox-icon" />}
                inputProps={{
                  "aria-label": "decorative checkbox",
                }}
                checked={isItemSelected}
              />
            </TableCell>
            <TableCell align="center" component="th" id={labelId} scope="row">
              <LevelComponent
                level={""}
                status={row.status.value}
                Id={labelId}
              />
            </TableCell>
            <TableCell
              align="left"
              className={status ? "deleted" : ""}
              onClick={() => {
                if (loc === "policy") {
                  dispatch({
                    type: "TRACK_TRAVERSAL",
                    payload: `Alert Policies - ${row.description}`,
                  });
                  history.push(`/tenant/alertPolicies/${row.id}`);
                }
              }}
            >
              {row.name}
            </TableCell>
            <TableCell align="left">{row.alertsCount}</TableCell>
            <TableCell
              align="left"
              className={status ? "deleted" : ""}
              onClick={() =>
                alertPolicyDataFlowGroupOpen(row.id, row.name, true)
              }
            >
              <span style={{ color: "blue", fontWeight: "500" }}>
                {row.dataFlowGroupsCount}
              </span>
            </TableCell>
            <TableCell align="left">
              {/* {row.type.value ? row.type.value : ""} */}
              {!row.automatedMonitoring
                ? row.trigger && row.trigger.triggerCondition
                  ? `${row.trigger.triggerCondition.comparisonType.value} by ${
                      row.trigger.triggerCondition.value
                    } ${
                      row.trigger.triggerCondition.aggregationType.value ===
                      "Percentage"
                        ? "%"
                        : ""
                    } on a ${
                      row.trigger.triggerCondition.timeWindowType.value
                    } basis.`
                  : "User Unauthorized Access"
                : `Auto Monitored`}
            </TableCell>
            <TableCell align="left">
              <div style={{ display: "flex" }}>
                <LevelComponent
                  level={row.severity.code}
                  status={""}
                  risk={row.riskScore === 0 ? "0" : row.riskScore}
                  Id={labelId}
                />
                &nbsp;
                <Button
                  disabled={row && row.status && row.status.code === "DELETED"}
                  style={{
                    minWidth: "0",
                    marginLeft: "5px",
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenSeverityPopUp("bottom", row)}
                    src={DropDownChevron}
                    alt="select-severity"
                  />
                </Button>
                <Popover
                  id={row.id}
                  open={severityPopUpOpen}
                  anchorEl={anchorEl}
                  onClose={handleCloseSeverityPopup}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px",
                    }}
                  >
                    {severities.map((each) => {
                      return (
                        <>
                          <div
                            className="severity-span"
                            onClick={() =>
                              changeAlertPolicySeveritySave(
                                selectedRowForSeverity,
                                each.value
                              )
                            }
                          >
                            <LevelComponent
                              level={each.display}
                              status={""}
                              Id={row.id}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Popover>
              </div>
            </TableCell>
          </>
        ) : loc === "drawer" ? (
          <>
            <TableCell
              className="accessAnalomy_TableRow"
              style={{ width: "115px" }}
              align="center"
            >
              <LevelComponent
                level={row.severity && row.severity.toUpperCase()}
                status={""}
                Id={labelId}
              />
            </TableCell>
            <TableCell
              className="accessAnalomy_TableRow"
              style={{ width: "85px" }}
              align="left"
            >
              {getTime(row.generatedOn)}
            </TableCell>
            <TableCell>{row.alertPolicy.name}</TableCell>
          </>
        ) : loc === "create-policy" ? (
          <>
            <TableCell align="center">{row.name}</TableCell>
            <TableCell align="center" component="th" id={labelId} scope="row">
              <LevelComponent
                level={""}
                status={row.sensitivity}
                Id={labelId}
              />
            </TableCell>
          </>
        ) : loc === "show-policy-flow-groups" ? (
          <>
            <TableCell align="left">
              <a href="#" onClick={() => selectDataFlowGroupHandle(row)}>
                {row.name}
              </a>
            </TableCell>
            <TableCell align="left">
              <a href="#" onClick={() => selectDataFlowGroupHandle(row)}>
                {row.aliasName}
              </a>
            </TableCell>
            <TableCell align="left">{row.flowAggregation}</TableCell>
            <TableCell align="left">{row.tracesCount}</TableCell>
            <TableCell align="center" component="th" id={labelId} scope="row">
              <LevelComponent
                level={""}
                status={row.sensitivity}
                Id={labelId}
              />
            </TableCell>
          </>
        ) : loc === "addDataFlowGroup" ? (
          <>
            <TableCell align="left">{row.name}</TableCell>
            <TableCell align="left">{row.aliasName}</TableCell>
            <TableCell align="left">{row.flowAggregation}</TableCell>
            <TableCell align="left">{row.tracesCount}</TableCell>
            <TableCell align="center" component="th" id={labelId} scope="row">
              <LevelComponent
                level={""}
                status={row.sensitivity}
                Id={labelId}
              />
            </TableCell>
            <TableCell align="center">
              {selectedDataFlowGroups.findIndex(
                (each) => each.id === row.id
              ) === -1 && (
                <AddButtonForAlertTable
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(event) =>
                    handleSelectDataFlowGroup(event, row, "add")
                  }
                  startIcon={<AddCircleOutlineIcon />}
                  className="min-margin"
                >
                  Add
                </AddButtonForAlertTable>
              )}
              {selectedDataFlowGroups.findIndex((each) => each.id === row.id) >
                -1 && (
                <AddedButtonForAlertTable
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(event) =>
                    handleSelectDataFlowGroup(event, row, "remove")
                  }
                  startIcon={<CheckCircleOutlinedIcon />}
                  className="min-margin"
                >
                  Added
                </AddedButtonForAlertTable>
              )}
            </TableCell>
          </>
        ) : loc === "review-data-sources" ? (
          <>
            <TableCell align="left">
              <span>{row.application.name}</span>
            </TableCell>
            <TableCell align="left" component="th" id={labelId} scope="row">
              {row.aliasName ? row.aliasName : row.name}
            </TableCell>
            <TableCell align="left">{row.type.value}</TableCell>
            <TableCell align="left">
              {row.connectionUrl !== null ? (
                <div style={{ overflowWrap: "anywhere" }}>
                  {row.connectionUrl}
                </div>
              ) : (
                <>
                  <InputBase
                    placeholder="Add URL Location"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={(event) => {
                      handleURLData(event);
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </>
              )}
            </TableCell>
            <TableCell align="left">
              {row.connectionUrl !== null ? (
                <DeleteButtonForToolBar
                  variant="contained"
                  id="delete"
                  size="small"
                  color="primary"
                  onClick={(event) => handleAddClick(event, row)}
                  className="min-margin"
                >
                  <EditOutlinedIcon />
                </DeleteButtonForToolBar>
              ) : (
                <AddDBButton
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(event) => handleAddClick(event, row)}
                  startIcon={<AddIcon />}
                  className="min-margin"
                ></AddDBButton>
              )}
            </TableCell>
          </>
        ) : loc === "data-sources" ? (
          <>
            <TableCell align="right">{row.applicationName}</TableCell>
            <TableCell align="left" component="th" id={labelId} scope="row">
              {row.dataSourceName}
            </TableCell>
            <TableCell align="left">{row.dataSourceType}</TableCell>
            <TableCell align="left">{row.container}</TableCell>
            <TableCell align="left">{row.field}</TableCell>
            <TableCell align="left">{row.description}</TableCell>
            <TableCell align="left">{row.dataType}</TableCell>
            <TableCell align="left">{row.sensitivityLabel}</TableCell>
            <TableCell align="left">{row.sensitivityLevel}</TableCell>
          </>
        ) : loc === "tenant-management-Tenants" ? (
          <>
            <TableCell padding="checkbox">
              <Checkbox
                className="checkbox-root"
                disableRipple
                color="default"
                onClick={(event) => {
                  handleClick(event, row.id, row.name);
                }}
                checkedIcon={
                  isItemSelected ? (
                    <span className="checked-icon" />
                  ) : (
                    <span className="checkbox-icon" />
                  )
                }
                icon={<span className="checkbox-icon" />}
                inputProps={{
                  "aria-label": "decorative checkbox",
                }}
                checked={isItemSelected}
              />
            </TableCell>
            <TableCell align="left">{row.name}</TableCell>
            <TableCell align="left">{row.platformSubdomainName}</TableCell>
            <TableCell align="left">{row.status.value}</TableCell>
            <TableCell align="center">
              <DeleteButtonForToolBar
                variant="contained"
                id="delete"
                size="small"
                color="primary"
                onClick={() => {
                  dispatch({
                    type: "TRACK_TRAVERSAL",
                    payload: `Platform -> Tenant Management ${row.name}`,
                  });
                  history.push({
                    pathname: `/platform/tenantManagement/${row.id}`,
                    state: {
                      name: row.name,
                      platformSubdomainName: row.platformSubdomainName,
                      features:
                        row.features &&
                        row.features &&
                        row.features.filter((data) => data.name === MDR),
                    },
                  });
                }}
                className="min-margin"
              >
                <EditOutlinedIcon />
              </DeleteButtonForToolBar>
            </TableCell>
          </>
        ) : loc === "tenant-management-Deleted" ? (
          <>
            <TableCell padding="checkbox">
              <Checkbox
                className="checkbox-root"
                disableRipple
                color="default"
                onClick={(event) => {
                  handleClick(event, row.id);
                }}
                checkedIcon={
                  isItemSelected ? (
                    <span className="checked-icon" />
                  ) : (
                    <span className="checkbox-icon" />
                  )
                }
                icon={<span className="checkbox-icon" />}
                inputProps={{
                  "aria-label": "decorative checkbox",
                }}
                checked={isItemSelected}
              />
            </TableCell>
            <TableCell align="left">{row.name}</TableCell>
            <TableCell align="left">{row.platformSubdomainName}</TableCell>
            <TableCell align="left">{row.status.value}</TableCell>
            <TableCell align="center">
              <DeleteButtonForToolBar
                variant="contained"
                size="small"
                color="primary"
                onClick={(event) => {
                  //todo restore API call
                }}
                className="min-margin"
                disabled
              >
                <Restore disabled />
              </DeleteButtonForToolBar>
            </TableCell>
          </>
        ) : loc === "users" ? (
          <>
            <CanAllow
              needs={{
                permission: [
                  userPermission(deleteTenantUser, deletePlatformUser),
                ],
              }}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  className="checkbox-root"
                  disableRipple
                  color="default"
                  onClick={(event) => {
                    handleClick(event, row.id);
                  }}
                  checkedIcon={
                    isItemSelected ? (
                      <span className="checked-icon" />
                    ) : (
                      <span className="checkbox-icon" />
                    )
                  }
                  icon={<span className="checkbox-icon" />}
                  inputProps={{
                    'aria-label': 'decorative checkbox',
                  }}
                  checked={isItemSelected}
                />
              </TableCell>
            </CanAllow>
            <TableCell align="left">
              {row.firstName || row.lastName
                ? `${row.firstName} ${row.lastName}`
                : ""}
            </TableCell>
            <TableCell align="left">
              {row.roles && row.roles.length > 0
                ?row.roles.map(role => role.name).join(", ")
                : "No Data Available"}
            </TableCell>
            <TableCell align="left">{row.email}</TableCell>
            <TableCell align="left">{row.phone}</TableCell>
            <TableCell align="left">
              {row.active ? "Active" : "Not Active"}
            </TableCell>
            <CanAllow
              needs={{
                permission: [
                  userPermission(deleteTenantUser, deletePlatformUser),
                ],
              }}
            >
              <TableCell align="center">
                <DeleteButtonForToolBar
                  variant="contained"
                  id="delete"
                  size="small"
                  color="primary"
                  onClick={() => {
                    if (
                      window.localStorage.getItem("tenant") !== "druvstar" &&
                      window.localStorage.getItem("tenant") !== "datavision"
                    ) {
                      if (window.location.pathname === "/mdr/userManagement") {
                        history.push(`/mdr/userManagement/editUser/${row.id}`);
                        dispatch({
                          type: "TRACK_TRAVERSAL",
                          payload: `User Management - Edit User - ${
                            row.firstName || ""
                          } ${row.lastName || ""}`,
                        });
                      }else if (window.location.pathname === "/gov/userManagement") {
                        history.push(`/gov/userManagement/editUser/${row.id}`);
                        dispatch({
                          type: "TRACK_TRAVERSAL",
                          payload: `User Management - Edit User - ${
                            row.firstName || ""
                          } ${row.lastName || ""}`,
                        });
                      } else {
                        dispatch({
                          type: "TRACK_TRAVERSAL",
                          payload: `User Management - Edit User - ${
                            row.firstName || ""
                          } ${row.lastName || ""}`,
                        });
                        history.push(
                          `/tenant/userManagement/editUser/${row.id}`
                        );
                      }
                    } else {
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: `User Management - Edit User -${
                          row.firstName || ""
                        } ${row.lastName || ""}`,
                      });
                      history.push(
                        `/platform/userManagement/editUser/${row.id}`
                      );
                    }
                  }}
                  className="min-margin"
                >
                  <EditOutlinedIcon />
                </DeleteButtonForToolBar>
              </TableCell>
            </CanAllow>
          </>
        ) : loc === "roles" ? (
          <>
            <TableCell padding="checkbox">
              <Checkbox
                className="checkbox-root"
                disableRipple
                color="default"
                onClick={(event) => {
                  handleClick(event, row.id);
                }}
                checkedIcon={
                  isItemSelected ? (
                    <span className="checked-icon" />
                  ) : (
                    <span className="checkbox-icon" />
                  )
                }
                icon={<span className="checkbox-icon" />}
                inputProps={{
                  "aria-label": "decorative checkbox",
                }}
                checked={isItemSelected}
              />
            </TableCell>
            <TableCell align="left">
              {row && row.name ? row.name : ""}
            </TableCell>
            <TableCell align="right">
              <DeleteButtonForToolBar
                disabled
                variant="contained"
                id="delete"
                size="small"
                color="primary"
                onClick={() => {
                  if (
                    window.localStorage.getItem("tenant") !== "druvstar" &&
                    window.localStorage.getItem("tenant") !== "datavision"
                  ) {
                    dispatch({
                      type: "TRACK_TRAVERSAL",
                      payload: `User Management - Edit Role - ${row.name}`,
                    });
                    history.push(`/tenant/userManagement/editRole/${row.id}`);
                  } else {
                    dispatch({
                      type: "TRACK_TRAVERSAL",
                      payload: `User Management - Edit Role - ${row.name}`,
                    });
                    history.push(`/platform/userManagement/editRole/${row.id}`);
                  }
                }}
                className="min-margin"
              >
                <EditOutlinedIcon />
              </DeleteButtonForToolBar>
            </TableCell>
          </>
        ) : loc === "platformSettingsPermissons" ? (
          <>
            <TableCell align="middle">{row.name}</TableCell>
            <TableCell align="middle">{row.role}</TableCell>
            <TableCell align="middle"></TableCell>
            <TableCell align="middle"></TableCell>
            <TableCell align="right">
              <DeleteButtonForToolBar
                key={index}
                variant="contained"
                id="delete"
                size="small"
                color="primary"
                onClick={(event) => {
                  permissonsClickHandler(event, row);
                }}
                className="min-margin"
              >
                <EditOutlinedIcon />
              </DeleteButtonForToolBar>
            </TableCell>
          </>
        ) : loc === "sql-statements" || loc === "OneDrive" ? (
          <>
            <TableCell align="left">
              {row[`DBStatements.sqlStatement`]}
            </TableCell>
          </>
        ) : loc === "span-sql-statements" ? (
          <>
            <TableCell align="left">
              {row[`SpanStatements.sqlStatement`]}
            </TableCell>
          </>
        ) : loc === "database_Users" ? (
          <>
            <TableCell padding="checkbox">
              <Checkbox
                className="checkbox-root"
                disableRipple
                color="default"
                onClick={(event) => {
                  handleClick(event, row.databaseAccessRequestID);
                }}
                checkedIcon={
                  isItemSelected ? (
                    <span className="checked-icon" />
                  ) : (
                    <span className="checkbox-icon" />
                  )
                }
                icon={<span className="checkbox-icon" />}
                inputProps={{
                  "aria-label": "decorative checkbox",
                }}
                checked={isItemSelected}
              />
            </TableCell>
            <TableCell align="left">{row.username}</TableCell>
            <TableCell align="left">
              {row.aliasName ? row.aliasName : row.dataSourceName}
            </TableCell>
            <TableCell align="left">
              {row.accessRoleNames &&
                row.accessRoleNames.map((each) => {
                  return (
                    <div
                      style={{
                        width: "175px",
                        wordBreak: "break-all",
                      }}
                    >
                      {each.name}
                    </div>
                  );
                })}
              {/* <div
                                  style={{
                                    width: "200px",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {row.accessRoleNames}
                                </div> */}
            </TableCell>
            <TableCell align="left">
              {row.deleted ? "INACTIVE" : "ACTIVE"}
            </TableCell>
            <TableCell align="left">{row.accountStatus}</TableCell>
            <TableCell align="left">
              {row.expiryDate
                ? moment(new Date(row.expiryDate)).format("MM-DD-YYYY")
                : "-"}
            </TableCell>
            <TableCell align="left" disabled>
              <DeleteButtonForToolBar
                variant="contained"
                id="delete"
                size="small"
                color="primary"
                onClick={(event) => {
                  dispatch(
                    setdatabaseAccessRequestId(row.databaseAccessRequestID)
                  );
                  dispatch(singleUserExpiryDate_EditClick(event.currentTarget));
                }}
                className="min-margin"
              >
                <EditOutlinedIcon />
              </DeleteButtonForToolBar>
            </TableCell>
          </>
        ) : loc === "Access_details" ? (
          <>
            <TableCell className="">{row.username}</TableCell>
            <TableCell align="left">
              {row.roles &&
                row.roles.map(
                  (el, index, arr) =>
                    el && (
                      <div
                        style={{
                          width: "120px",
                          wordBreak: "break-all",
                        }}
                      >{`${el}${index !== arr.length - 1 ? "," : ""}`}</div>
                    )
                )}
            </TableCell>
            <TableCell
              align="left"
              style={{
                width: "130px",
                wordBreak: "break-all",
              }}
            >
              {row.dataSourceName}
            </TableCell>

            <TableCell align="left" className="accessDetailsRow">
              {row.sensitiveDataAccessed}
            </TableCell>

            <TableCell
              align="left"
              className="accessDetailsRow_Lesswidth"
              style={{ width: "50px", height: "50px" }}
            >
              <div>
                <CircularProgressbar
                  value={row.sensitiveDataAccessPercentage}
                  text={`${row.sensitiveDataAccessPercentage}%`}
                  styles={buildStyles({
                    pathColor: `${
                      row.sensitiveDataAccessPercentage <= 30
                        ? "green"
                        : row.sensitiveDataAccessPercentage <= 60
                        ? "yellow"
                        : "red"
                    }`,
                  })}
                />
              </div>
            </TableCell>
            <TableCell
              align="left"
              className="accessDetailsRow"
              style={{ width: "25px" }}
            >
              {row.totalAnomalies}
            </TableCell>
            <TableCell align="left" className="accessDetailsRow">
              <div className={`accessDetailsRow ${row.highestAlert}`}>
                {row.highestAlert ? row.highestAlert : "-"}
              </div>
            </TableCell>
            <TableCell align="left" className="accessDetailsRow_Lesswidth">
              {row.firstAccessedOn
                ? moment(new Date(row.firstAccessedOn)).format(
                    "MM-DD-YYYY HH:mm"
                  )
                : "-"}
            </TableCell>
            <TableCell align="left" className="accessDetailsRow_Lesswidth">
              {row.lastAccessedOn
                ? moment(new Date(row.lastAccessedOn)).format(
                    "MM-DD-YYYY HH:mm"
                  )
                : "-"}
            </TableCell>
          </>
        ) : loc === "accessAnalomy" ? (
          <>
            {/* <div  onClick={() => {
                                history.push({
                                  pathname: "/tenant/databaseLogs",
                                  state: {alertId :row.alertId,configuartion:row.configuartion}
                              })
                              }}> */}
            <TableCell
              align="left"
              className="accessAnalomy_TableRow"
              style={{
                width: "115px",
                wordBreak: "break-all",
                padding: "10px",
              }}
            >
              {row.timestamp ? renderDateTime(row.timestamp) : ""}
            </TableCell>

            <TableCell
              align="left"
              className="accessAnalomy_TableRow"
              style={{
                width: "85px",
                wordBreak: "break-all",
              }}
            >
              {row.policyViolation ? row.policyViolation : ""}
            </TableCell>
            <TableCell
              align="left"
              className="accessAnalomy_TableRow"
              style={{
                width: "225px",
                wordBreak: "break-all",
              }}
            >
              {row.reason ? row.reason : ""}
            </TableCell>
            <TableCell
              align="left"
              className="accessAnalomy_TableRow"
              style={{
                width: "20px",
                wordBreak: "break-all",
              }}
            >
              {row.alertType ? row.alertType : ""}
            </TableCell>
            <TableCell
              align="left"
              className="accessAnalomy_TableRow"
              style={{
                width: "20px",
                wordBreak: "break-all",
              }}
            >
              <div className={`accessAnalomy_Severity ${row.severity}`}>
                {row.severity ? row.severity : ""}
              </div>
            </TableCell>
            {/* </div> */}
          </>
        ) : loc === "accessAnalomy_serviceDataAccesses" ? (
          <>
            <TableCell
              align="left"
              className="accessAnalomy_TableRow"
              style={{
                width: "115px",
                wordBreak: "break-all",
                padding: "10px",
              }}
            >
              {row.timestamp ? renderDateTime(row.timestamp) : ""}
            </TableCell>

            <TableCell
              align="left"
              className="accessAnalomy_TableRow"
              style={{
                width: "85px",
                wordBreak: "break-all",
              }}
            >
              {row.policyViolation ? row.policyViolation : ""}
            </TableCell>
            <TableCell
              align="left"
              className="accessAnalomy_TableRow"
              style={{
                width: "225px",
                wordBreak: "break-all",
              }}
            >
              {row.reason ? row.reason : ""}
            </TableCell>
            <TableCell
              align="left"
              className="accessAnalomy_TableRow"
              style={{
                width: "20px",
                wordBreak: "break-all",
              }}
            >
              {row.alertType ? row.alertType : ""}
            </TableCell>
            <TableCell
              align="left"
              className="accessAnalomy_TableRow"
              style={{
                width: "20px",
                wordBreak: "break-all",
              }}
            >
              <div className={`accessAnalomy_Severity ${row.severity}`}>
                {row.severity ? row.severity : ""}
              </div>
            </TableCell>
            {/* </div> */}
          </>
        ) : loc === "datasourceDetails_serviceDataAccesses" ? (
          <>
            <TableCell align="left" className="datasourceDetails_TableRow">
              {row.dataSourceName ? row.dataSourceName : "-"}
            </TableCell>

            <TableCell align="left" className="datasourceDetails_TableRow">
              {row.dataSourceRole ? row.dataSourceRole : "-"}
            </TableCell>
            <TableCell align="left" className="datasourceDetails_TableRow">
              {row.dataSourceUserName ? row.dataSourceUserName : "-"}
            </TableCell>
          </>
        ) : loc === "All_Access" ? (
          <>
            <TableCell
              align="left"
              className="allAccessRow"
              style={{
                width: "110px",
                wordBreak: "break-all",
              }}
            >
              {/* {JSON.stringify(row)} */}
              {row.timestamp ? renderDateTime(row.timestamp) : ""}
            </TableCell>

            <TableCell align="left" className="allAccessRow">
              <div
                style={{
                  wordBreak: "break-all",
                  maxWidth: "480px",
                  fontFamily: "Nunito-Regular",
                  lineHeight: "25px",
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitizeDOMString(row.dbStatement),
                }}
              ></div>
            </TableCell>
          </>
        ) : loc === "All_Access_File_PopUp" ? (
          <>
            <TableCell
              align="left"
              className="allAccessRow"
              style={{
                width: "110px",
                wordBreak: "break-all",
              }}
            >
              {/* {JSON.stringify(row)} */}
              {row.timeStamp ? renderDateTime(row.timeStamp) : ""}
            </TableCell>
            <TableCell
              align="left"
              className="allAccessRow"
              style={{
                width: "110px",
                wordBreak: "break-all",
              }}
            >
              {/* {JSON.stringify(row)} */}
              {row.userName ? row.userName : ""}
            </TableCell>

            <TableCell align="left" className="allAccessRow">
              <div
                style={{
                  wordBreak: "break-all",
                  maxWidth: "480px",
                  fontFamily: "Nunito-Regular",
                  lineHeight: "25px",
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitizeDOMString(row.dbStatement),
                }}
              ></div>
            </TableCell>
          </>
        ) : loc === "All_Access_serviceDataAccesses" ? (
          <>
            <TableCell
              align="left"
              className="allAccessRow"
              style={{
                width: "110px",
                wordBreak: "break-all",
              }}
            >
              {/* {JSON.stringify(row)} */}
              {row.timestamp ? renderDateTime(row.timestamp) : ""}
            </TableCell>

            <TableCell align="left" className="allAccessRow">
              <div
                style={{
                  wordBreak: "break-all",
                  maxWidth: "480px",
                  fontFamily: "Nunito-Regular",
                  lineHeight: "25px",
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitizeDOMString(row.dbStatement),
                }}
              ></div>
            </TableCell>
          </>
        ) : loc === "sensitiveAccess_serviceDataAccesses" ? (
          <>
            <TableCell align="left" className="accessDetailsRow">
              {row.timeStamp ? renderDateTime(row.timeStamp) : ""}
            </TableCell>

            <TableCell align="left" className="accessDetailsRow">
              <>
                <LightTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        <u>Field Information</u>
                      </Typography>
                      <span>
                        Table Name: <b>{row && row.tableName}</b>
                      </span>
                      <br />
                      <span>
                        Field Name: <b>{row && row.columnName}</b>
                      </span>
                    </React.Fragment>
                  }
                  arrow
                >
                  <div className="sensitiveAccess_Container">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-around"
                    >
                      <Grid item xs={9} sm={9}>
                        <div
                          style={{
                            marginLeft: "10px",
                            overflow: "hidden",
                            whiteSpace: "initial",
                            wordWrap: "break-word",
                            fontFamily: "Nunito-Regular",
                          }}
                        >
                          {row.columnName
                            ? `${row.tableName ? row.tableName + "." : ""}${
                                row.columnName
                              }`
                            : ""}
                        </div>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <div className={`sensitiveAccess ${row.sensitivity}`}>
                          {row.sensitivity ? row.sensitivity : ""}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </LightTooltip>
              </>
            </TableCell>
          </>
        ) : loc === "sensitiveAccess" ? (
          <>
            <TableCell align="left" className="accessDetailsRow">
              {row.timeStamp ? renderDateTime(row.timeStamp) : ""}
            </TableCell>

            <TableCell align="left" className="accessDetailsRow">
              <>
                <div className="sensitiveAccess_Container">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-around"
                  >
                    <Grid item xs={9} sm={9}>
                      <div
                        style={{
                          marginLeft: "10px",
                          overflow: "hidden",
                          whiteSpace: "initial",
                          wordWrap: "break-word",
                          fontFamily: "Nunito-Regular",
                        }}
                      >
                        {row.columnName
                          ? `${row.tableName ? row.tableName + "." : ""}${
                              row.columnName
                            }`
                          : ""}
                      </div>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <div className={`sensitiveAccess ${row.sensitivity}`}>
                        {row.sensitivity ? row.sensitivity : ""}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </>
            </TableCell>
          </>
        ) : loc === "MDRDashboard_IncidentTable" ? (
          <>
            <TableCell align="left" style={{ width: "150px" }}>
              {renderDateTime(
                parseInt(row["IncidentsTable.incidentTime"]) + diff * 60 * 1000
              )}
            </TableCell>
            <TableCell align="left" style={{ width: "110px" }}>
              {row["IncidentsTable.incidentId"]}
            </TableCell>
            <TableCell align="left" style={{ width: "180px" }}>
              <span
                className={
                  row["IncidentsTable.priority"] &&
                  `mdrIncidentTableSeverityColumn ${row[
                    "IncidentsTable.priority"
                  ].toLowerCase()}`
                }
                arrow
              >
                {row["IncidentsTable.priority"]}
              </span>
            </TableCell>
            <TableCell align="left" style={{ width: "110px" }}>
              {row["IncidentsTable.status"]}
            </TableCell>
            <TableCell align="left" style={{ width: "110px" }}>
              {row["IncidentsTable.name"]}
            </TableCell>
          </>
        ) : loc === "MDRDashboard_AuthenticationTable_AllUsers" ? (
          <>
            <TableCell align="left" className="" style={{ width: "110px" }}>
              {renderDateTime(row["MdrAuthentication.creation_time"])}
            </TableCell>
            <TableCell align="left" className="" style={{ width: "110px" }}>
              {row["MdrAuthentication.ipAddress"]}
            </TableCell>
            <TableCell align="left" className="" style={{ width: "110px" }}>
              {row["MdrAuthentication.authErrorSource"]}
            </TableCell>
            <TableCell align="left" className="" style={{ width: "110px" }}>
              {row["MdrAuthentication.userName"]}
            </TableCell>
            <TableCell align="left" className="" style={{ width: "110px" }}>
              {row["MdrAuthentication.result"]}
            </TableCell>
          </>
        ) : loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ? (
          <>
            <TableCell align="left" className="" style={{ minWidth: "180px" }}>
              {row["MDRAuthenticationDistinctUsers.ipAddress"]}
            </TableCell>
            <TableCell align="left" className="" style={{ minWidth: "180px" }}>
              {row["MDRAuthenticationDistinctUsers.userName"]}
            </TableCell>
            <TableCell align="left" className="" style={{ minWidth: "180px" }}>
              {row["MDRAuthenticationDistinctUsers.loginFailureCount"]}
            </TableCell>
          </>
        ) : loc === "MDRDashboard_AuthenticationTable_UniqueIPs" ? (
          <>
            <TableCell align="left" className="" style={{ minWidth: "180px" }}>
              {row["MDRAuthenticationDistinctIP.userName"]}
            </TableCell>
            <TableCell align="left" className="" style={{ minWidth: "180px" }}>
              {row["MDRAuthenticationDistinctIP.ipAdressCount"]}
            </TableCell>
            <TableCell align="left" className="" style={{ minWidth: "180px" }}>
              {row["MDRAuthenticationDistinctIP.loginFailureCount"]}
            </TableCell>
          </>
        ) : loc === "MDRDashboard_EndPointsTable" ? (
          <>
            <TableCell align="left" className="" style={{ width: "18%" }}>
              {renderDateTime(row["MDREndPoints.creation_time"])}
            </TableCell>
            <TableCell align="left" className="" style={{ width: "14%" }}>
              {row["MDREndPoints.hostName"]}
            </TableCell>
            <TableCell align="left" className="" style={{ width: "14%" }}>
              {row["MDREndPoints.ipv4"]}
            </TableCell>
            <TableCell align="left" className="" style={{ width: "8%" }}>
              {row["MDREndPoints.score"]}
            </TableCell>
            <TableCell align="left" className="" style={{ width: "8%" }}>
              {row["MDREndPoints.environment"]}
            </TableCell>
            <TableCell align="left" className="" style={{ width: "14%" }}>
              {row["MDREndPoints.osDescription"]}
            </TableCell>

            <TableCell align="left" className="" style={{ width: "14%" }}>
              {row["MDREndPoints.userName"]}
            </TableCell>
          </>
        ) : loc === "MDRDashboard_PatchMonitoring" ? (
          <>
            <TableCell
              align="left"
              className="MDRDashboard_PatchMonitoring"
              style={{ width: "20px" }}
            >
              {renderDateTime(row["MDRPatchManagement.creation_time"])}
            </TableCell>
            <TableCell
              align="left"
              className="MDRDashboard_PatchMonitoring"
              style={{
                maxWidth: "120px",
                whiteSpace: "initial",
                wordWrap: "break-word",
              }}
            >
              {row["MDRPatchManagement.server"]}
            </TableCell>
            <TableCell align="left" className="MDRDashboard_PatchMonitoring">
              {row["MDRPatchManagement.subscriptionName"]}
            </TableCell>
            <TableCell
              align="left"
              className="MDRDashboard_PatchMonitoring lessWidth"
            >
              {row["MDRPatchManagement.classification"]}
            </TableCell>
            <TableCell
              align="left"
              className="MDRDashboard_PatchMonitoring lessWidth"
            >
              {row["MDRPatchManagement.severity"]}
            </TableCell>
            <TableCell
              align="left"
              className="MDRDashboard_PatchMonitoring lessWidth"
            >
              {row["MDRPatchManagement.updateState"]}
            </TableCell>
            <TableCell
              align="left"
              className="MDRDashboard_PatchMonitoring lessWidth"
            >
              {row["MDRPatchManagement.kbid"]}
            </TableCell>

            <TableCell
              align="left"
              className="MDRDashboard_PatchMonitoring extrawidth"
            >
              {row["MDRPatchManagement.title"]}
            </TableCell>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
  const renderTableRow = (row, isItemSelected, status, index, labelId) => {
    return (
      <TableRow
        className={`enhanced-table-row  ${status ? "list-deleted" : ""}`}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row && row.id}
        selected={isItemSelected}
      >
        {renderTableCells(row, isItemSelected, status, index, labelId)}
      </TableRow>
    );
  };
  return (
    <div className="enhanced-table-root">
      <Paper className="paper">
        <EnhancedTableToolbar
          rows={rows}
          handleCheckbox={handleCheckbox}
          clearFilterButtonClick={clearFilterButtonClick}
          numSelected={selected.length}
          selectedRows={selected}
          num={getCount()}
          isClick={(event) => setIsClick(event)}
          handleToggleFilter={handleToggleFilter}
          loc={loc}
          handleSortingFilter={handleSortingFilter}
          severityFilter={severityFilter}
          resolutionFilter={resolutionFilter}
          policyFilter={policyFilter}
          handleDataFlowGroupOpen={handleDataFlowGroupOpen}
          history={history}
          sortBy={sortBy}
          newAlertPolicyHide={newAlertPolicyHide}
          deletePolicyBtnHide={deletePolicyBtnHide}
          rolesClickHandler={rolesClickHandler}
          selectedTenantForDelete={selectedTenantForDelete}
          project={project}
          type={type}
          policyId={policyId}
          description={description}
          // totalElements={totalElements}
        />
        {loader && loc !== "database_Users" && loc !== "alerts" ? (
          <LoaderComponent />
        ) : (
          ""
        )}
        {loader && loc === "alerts" ? (
          <div className="global-loader-holder">
            <img src={Page_Loader} alt="_Loader" className="loader" />
          </div>
        ) : (
          ""
        )}
        {rows.length > 0 && (
          <React.Fragment>
            <TableContainer
              className={
                loc === "data-sources"
                  ? "enhanced-table-container data-sources"
                  : loc === "accessAnalomy"
                  ? "enhanced-table-container accessAnalomy"
                  : loc === "All_Access"
                  ? "enhanced-table-container All_Access"
                  : loc === "All_Access_File_PopUp"
                  ? "enhanced-table-container All_Access"
                  : loc === "sensitiveAccess"
                  ? "enhanced-table-container All_Access"
                  : loc ==="OneDrive"
                  ?"enhanced-table-container one_drive_access_statements"
                  :"enhanced-table-container"
              }
            >
              <Table
                className={
                  loc === "accessAnalomy" ||
                  loc === "All_Access" ||
                  loc === "All_Access_File_PopUp" ||
                  loc === "sensitiveAccess" ||
                  loc === "accessAnalomy_serviceDataAccesses" ||
                  loc === "All_Access_serviceDataAccesses" ||
                  loc === "sensitiveAccess_serviceDataAccesses" ||
                  loc === "datasourceDetails_serviceDataAccesses"
                    ? "enhanced-table AccessDetailsPopUpTable"
                    : "enhanced-table"
                }
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
                // style={{ position: "sticky", left: 0 }}
                // stickyHeader
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={getRows().length}
                  headCells={headCells}
                  loc={loc}
                  rows={getRows()}
                  totalElements={totalElements}
                  project={project}
                  policyId={policyId}
                  color={color}
                />
                <TableBody
                  style={{ height: "500px !important", overflowY: "hidden" }}
                >
                  {rows &&
                    getRows().map((row, index) => {
                      const isItemSelected = isSelected(
                        loc === "database_Users"
                          ? row.databaseAccessRequestID
                          : row
                          ? row.id
                          : ""
                      );
                      const status =
                        loc === "policy" &&
                        row &&
                        row.status &&
                        row.status.code === "DELETED";
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <CanAllow
                          needs={{ permission: needs[loc] }}
                          component={renderTableRow(
                            row,
                            isItemSelected,
                            status,
                            index,
                            labelId
                          )}
                        >
                          <TableRow
                            className={`enhanced-table-row  ${
                              status ? "list-deleted" : ""
                            }`}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row && row.id}
                            selected={isItemSelected}
                            onClick={() => tableRowsClickHandler(row)}
                          >
                            {renderTableCells(
                              row,
                              isItemSelected,
                              status,
                              index,
                              labelId
                            )}
                          </TableRow>
                        </CanAllow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 20, 50, 100, 200]}
              component="div"
              count={getCount()}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </React.Fragment>
        )}

        {(rows.length === 0 && loc === "database_Users" && !dataLoaded) ||
        (rows.length === 0 && loc === "Access_details" && !dataLoaded) ||
        (rows.length === 0 && loc === "users" && !dataLoaded) ? (
          <div
            style={{
              marginLeft: "42%",
              marginTop: "20px",
              paddingBottom: "20px",
              fontWeight: "900",
            }}
          >
            <img src={Page_Loader} alt="page_loader"></img>
          </div>
        ) : (rows.length === 0 && loc === "database_Users" && dataLoaded) ||
          (rows.length === 0 && loc === "Access_details" && dataLoaded) ? (
          <h2
            style={{
              marginLeft: "42%",
              marginTop: "20px",
              paddingBottom: "20px",
              fontWeight: "900",
            }}
          >
            No Data To Show.
          </h2>
        ) : (
          rows.length === 0 && (
            <h2
              style={{
                marginLeft: "42%",
                marginTop: "20px",
                paddingBottom: "20px",
                fontWeight: "900",
              }}
            >
              No Data To Show.
            </h2>
          )
        )}
        {rows.length < rowsPerPage * page + 1 &&
        dataLoaded &&
        rows.length !== 0 &&
        (loc === "users" ||
          loc === "roles" ||
          loc === "database_Users" ||
          loc === "Access_details" ||
          loc === "accessAnalomy" ||
          loc === "All_Access" ||
          loc === "All_Access_File_PopUp" ||
          loc === "sensitiveAccess" ||
          loc === "accessAnalomy_serviceDataAccesses" ||
          loc === "All_Access_serviceDataAccesses" ||
          loc === "sensitiveAccess_serviceDataAccesses" ||
          loc === "datasourceDetails_serviceDataAccesses") ? (
          <LoaderComponent></LoaderComponent>
        ) : rows.length < rowsPerPage * page + 1 &&
          rows.length !== 0 &&
          !searchValue &&
          (loc === "users" ||
            loc === "roles" ||
            loc === "database_Users" ||
            loc === "Access_details" ||
            loc === "accessAnalomy" ||
            loc === "All_Access" ||
            loc === "All_Access_File_PopUp" ||
            loc === "sensitiveAccess" ||
            loc === "accessAnalomy_serviceDataAccesses" ||
            loc === "All_Access_serviceDataAccesses" ||
            loc === "sensitiveAccess_serviceDataAccesses" ||
            loc === "datasourceDetails_serviceDataAccesses") ? (
          <LoaderComponent></LoaderComponent>
        ) : (
          ""
        )}
      </Paper>
      {(loc === "MDRDashboard_IncidentTable" ||
        loc === "MDRDashboard_PatchMonitoring" ||
        loc === "MDRDashboard_EndPointsTable" ||
        loc === "MDRDashboard_AuthenticationTable_UniqueUsers" ||
        loc === "MDRDashboard_AuthenticationTable_AllUsers" ||
        loc === "MDRDashboard_AuthenticationTable_UniqueIPs") && (
        <AlertDrawer loc={loc}></AlertDrawer>
      )}
      {loc === "Access_details" && (
        <AlertDrawer
          accessDetailsDrawer={accessDetailsDrawer}
          loc={loc}
        ></AlertDrawer>
      )}
      {selectedAlertId !== "" && (
        <AlertDrawer
          alertDetailsId={selectedAlertId}
          toggleDrawer={toggleDrawer}
          stateOpen={stateOpen}
        />
      )}
    </div>
  );
}