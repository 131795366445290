import React from "react";
import SignUpBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
import ManagementContent from "../CreateUser/ManagementContent";

const CreateUser = () => {
  const getURL = () => {
    if (window.location.pathname === "/mdr/userManagement/createUsers") {
      return "/mdr/userManagement/createUsers";
    } else if (window.location.pathname === "/gov/userManagement/createUsers") {
      return "/gov/userManagement/createUsers";
    } else {
      return "/tenant/userManagement";
    }
  }
  const tenant = window.localStorage.getItem("tenant");
  return (
    <div>
      <SignUpBackGround
        headerName="User Onboarding"
        header="User Onboarding"
        breadcrumbValue={[
          {
            value: "User Management",
            link:
              tenant !== "druvstar" && tenant !== "datavision"
                ? getURL()
                : "/platform/userManagement",
          },
          { value: "Create", link: "" },
        ]}
        // type="marginRequired"
      ></SignUpBackGround>
      <ManagementContent></ManagementContent>
    </div>
  );
};

export default CreateUser;
