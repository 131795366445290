import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import Navbar from "./MDRNavbar";
import MDRDashboard from "../../views/DashboardMDR";
import { Route, Switch, Redirect } from "react-router-dom";
import ProfileEditing from "../../views/ProfileEditing/ProfileEditing";
import MDRSettings from "../../views/MDRSettings/MDRSettings";
import CreateUser from "../../views/CreateUser/CreateUser";
import UserManagement from "../../views/UserMangement/UserManagement";
import EditUsers from "../../views/EditUser/EditUsers";
import routes from "../../../routes";
import { cloneDeep } from "lodash";
import MDRConfig from "./MDRConfig";
import NetSpace from "../../../features/NetSpace/NetSpace";
import RiskPilot from "../../views/RiskPilot/RiskPilot";
import { RISK } from "../../../redux/constants/constants";
import NoPermissionView from "../../views/NoPermission/NoPermissionView";

export default function MDRContainer() {
  const { backgroundColor, setBackgroundColor } = useState("blue");
  const [expand, setExpand] = useState(true);
  const permissions = useSelector((state) => state.applicationData.permissions);
  const featuresData = JSON.parse(localStorage.getItem("featuresInfo"));
  const features =
    featuresData &&
    featuresData.features &&
    featuresData.features.map((data) => data.name);

  const handleShrink = () => {
    setExpand(!expand);
  };
  useEffect(() => {
    localStorage.setItem("project", "MDR");

    return () => {
      localStorage.setItem("mdrCurrentUser", "");
      localStorage.setItem("project", "");
    };
  }, []);

  const [sidebarLinks, setSidebarLinks] = useState([]);

  useEffect(() => {
    const routesData = cloneDeep(routes);
    if (routesData.length > 0) {
      let temp = [];
      routesData.map((each) => {
        let obj = each;
        let str;

        if (each.innerLinks) {
          each.innerLinks.map((item, index) => {
            let str1 = `/tenant${item.path}`;
            obj.innerLinks[index].path = str1;
            return null;
          });
        } else {
          str = `/tenant${obj.path}`;
        }

        obj.path = str;
        temp.push(obj);
        return null;
      });

      console.log(temp, "testing");
      setSidebarLinks(temp);
    }
    return () => {
      setSidebarLinks([]);
    };
  }, [routes]);

  return (
    <>
      <div className="wrapper">
        <Sidebar
          routes={sidebarLinks}
          bgColor={backgroundColor}
          expand={expand}
          handleShrink={handleShrink}
        />
        <div
          className={expand ? "main-panel" : "main-panel shrunk"}
          data={backgroundColor}
        >
          <Navbar />
          <Switch>
            <Redirect
              path={"/MDRdashboards"}
              to={`/mdr/MDRdashboards`}
            ></Redirect>
            <Route path={`/mdr/MDRdashboards`} component={MDRDashboard} />
            <Route exact path={`/mdr/Settings`} component={MDRSettings} />
            <Route exact path={`/mdr/netspace`} component={NetSpace}/>
            <Route
              exact
              path={`/mdr/Settings/ipConfiguration`}
              component={MDRConfig}
            />
            <Route
              exact
              path={`/mdr/Settings/accountIdConfiguration`}
              component={MDRConfig}
            />
            <Route path={`/mdr/profileEditing`} component={ProfileEditing} />
            {permissions && permissions.includes("ADD_TENANT_USER") && (
              <Route
                exact
                path={`/mdr/userManagement/createUsers`}
                component={CreateUser}
              />
            )}
            {permissions && permissions.includes("VIEW_TENANT_USER") && (
              <Route
                exact
                path={`/mdr/userManagement`}
                component={UserManagement}
              />
            )}
            {permissions && permissions.includes("UPDATE_TENANT_USER") && (
              <Route
                exact
                path={`/mdr/userManagement/editUser/:id`}
                component={EditUsers}
              />
            )}
          </Switch>
        </div>
      </div>
    </>
  );
}
