import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import { CubeProvider } from "@cubejs-client/react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { getCubejsApi } from "../common/Dashboard/cubejs-util";
import { getChart } from "../common/Dashboard/chartTypeToComponent";
import {
  IncidentdashboardInstance,
  AuthenticationdashboardInstance,
  EndPointsdashboardInstance,
  PatchMonitoringdashboardInstance,
  PatchMonitoringdashboardInstanceClone,
} from "../common/Dashboard/dashboard-data";
import Modal from "@material-ui/core/Modal";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import "./DashboardStyles.scss";

import {} from "../../components/common/headCells";
import {
  dateFormatter,
  updateQueryAsperDateRange,
} from "../common/Dashboard/utils";

import // incidentTableFilter_Query_toGet_FilterValues,
// incidentTableFilters,
"../../../src/redux/constants/constants";
// import UseIncidentFilterHook from "../common/Dashboard/MDRTables/IncidentFilterHook";
import TableFiltersLoad from "../common/Dashboard/MDRTables/TableFiltersLoad";
import DashboardBanner from "../../../src/components/shared/DashboardBanner";
import { MDR, RISK } from "../../redux/constants/constants";
import DashboardMDRGlobalFilters from "./DashboardMDRGlobalFilters";

import {
  addGlobalFiltersForDashboard,
  masterArr,
} from "../../components/common/Dashboard/MDRHelpers";
import { cloneDeep } from "lodash";
import { RiskPilotDashboardInstance } from "./RiskPilot/Dashboards/riskPilotDashboardData";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    position: "absolute",
    width: 400,
    border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: "5px",
    backgroundColor: "#fff",
  },
});

function DashboardMDRWidgets({ globalFiltersIntialList, setGlobalFilters }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const incidentdashboardInstance = useSelector(
    (state) => state.IncidenetReducer.incidentdashboardInstance
  );
  console.log(incidentdashboardInstance, "incidentdashboardInstance");
  const renderforDateChange = useSelector(
    (state) => state.IncidenetReducer.renderforDateChange
  );

  const dateTimeRange = useSelector(
    (state) => {
      return state.selectedDateTimeRange.dateTimeRange
    }
  );

  const [dashboardConfig, setDashboardConfig] = React.useState();

  const featuresData = JSON.parse(localStorage.getItem("featuresInfo"));
  const features =
  featuresData &&
  featuresData.features &&
  featuresData.features.filter((data) => data.name === MDR);

  const allServices = features.flatMap(item => item.services);

  const calculateStartdateAndEndDate = () => {
    let startDate = dateTimeRange && JSON.parse(dateTimeRange).startDate;
    let endDate = dateTimeRange && JSON.parse(dateTimeRange).endDate;
    const d = new Date();
    let diff = d.getTimezoneOffset();
    console.log(diff, "diff");
    let startDt = new Date(startDate).getTime() - diff * 60 * 1000; // dateFormatter(startDate, "MM-DD-YYYY HH:mm:ss");
    let endDt = new Date(endDate).getTime() - diff * 60 * 1000; // dateFormatter(endDate, "MM-DD-YYYY HH:mm:ss");
    // let startDt = new Date(startDate).getTime();
    // let endDt = new Date(endDate).getTime();
    localStorage.setItem("startDt", startDt);
    localStorage.setItem("endDt", endDt);
    return { startDate: startDt, endDate: endDt };
  };
  useEffect(() => {
    let startDate = dateTimeRange && JSON.parse(dateTimeRange).startDate;
    let endDate = dateTimeRange && JSON.parse(dateTimeRange).endDate;
    if (startDate && endDate) {
      const d = new Date();
      let diff = d.getTimezoneOffset();
      console.log(diff, "diff");
      let startDt = new Date(startDate).getTime() - diff * 60 * 1000; // dateFormatter(startDate, "MM-DD-YYYY HH:mm:ss");
      let endDt = new Date(endDate).getTime() - diff * 60 * 1000; // dateFormatter(endDate, "MM-DD-YYYY HH:mm:ss");

      //Need it fo furthur reference
      // localStorage.setItem("startDt", startDt);
      // localStorage.setItem("endDt", endDt);
      // let startDt = startDate;
      // let endDt = endDate;
      // let startDt = new Date(startDate).getTime();
      // let endDt = new Date(endDate).getTime();
      dispatch({
        type: "UPDATE_QUERIES_ASPER_DATARANGE_SELECTED",
        payload: {
          startDate: startDt,
          endDate: endDt,
          dateTimeRange: dateTimeRange,
        },
      });
    }
  }, [dateTimeRange]);

  // Todo: To make re-render when ever the Date Updated

  useEffect(() => {
    setDashboardConfig([...incidentdashboardInstance]);
  }, [incidentdashboardInstance, renderforDateChange]);

  useEffect(() => {
    if (allServices && allServices.length > 0) {
      console.log(localStorage.getItem("mdrCurrentUser"));
      setCurrentUser(
        localStorage.getItem("mdrCurrentUser")
          ? localStorage.getItem("mdrCurrentUser")
          : allServices[0].name
      );
      if (dateTimeRange) {
        setDefaultDashboard(
          localStorage.getItem("mdrCurrentUser")
            ? localStorage.getItem("mdrCurrentUser")
            : allServices[0].name
        );
      }
      // setDashboardFeatures(features)
    }
  }, [dateTimeRange]);

  const [currentUser, setCurrentUser] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const setDefaultDashboard = (value) => {
    let dashboardConfig;

    switch (value) {
      case "Incidents":
        let incidentInstance = [...IncidentdashboardInstance];
        let updatedIncidentsdashboardInstance = updateQueryAsperDateRange(
          incidentInstance,
          {
            payload: calculateStartdateAndEndDate(),
          },
          dateTimeRange
        );
        dispatch({
          type: "INCIDENT_TABLE_FILTER_CLEARALL",
        });
        dispatch({
          type: "CHANGE_MDR_DASHBOARD",
          payload: updatedIncidentsdashboardInstance,
        });
        dispatch({ type: "UPDATE_MDR_DROPDOWN_VALUE", payload: "Incidents" });
        setCurrentUser("Incidents");
        break;
      case "Authentication":
        let data = [...AuthenticationdashboardInstance];
        let updatedAuthenticationdashboardInstance = updateQueryAsperDateRange(
          data,
          {
            payload: calculateStartdateAndEndDate(),
          },
          dateTimeRange
        );
        dispatch({
          type: "MDR_AUTHENTICATION_FILTERS_CLEARALL",
        });
        dispatch({
          type: "CHANGE_MDR_DASHBOARD",
          payload: updatedAuthenticationdashboardInstance,
        });
        dispatch({
          type: "UPDATE_MDR_DROPDOWN_VALUE",
          payload: "Authentication",
        });
        setCurrentUser("Authentication");

        break;
      case "Endpoints":
        // EndPointsdashboardInstance
        let data3 = [...EndPointsdashboardInstance];
        let updatedEndPointdashboardInstance = updateQueryAsperDateRange(
          data3,
          {
            payload: calculateStartdateAndEndDate(),
          },
          dateTimeRange
        );
        dispatch({
          type: "CHANGE_MDR_DASHBOARD",
          payload: updatedEndPointdashboardInstance,
        });

        dispatch({
          type: "UPDATE_MDR_DROPDOWN_VALUE",
          payload: "Endpoints",
        });

        setCurrentUser("Endpoints");

        break;
      case "Patch Monitoring":
        let patchmonitorUpdatedData = [...PatchMonitoringdashboardInstance()];
        let updatedPatchMonotoringdashboardInstance = updateQueryAsperDateRange(
          patchmonitorUpdatedData,
          {
            payload: calculateStartdateAndEndDate(),
          },
          dateTimeRange
        );
        dispatch({
          type: "UPDATE_MDR_DROPDOWN_VALUE",
          payload: "Patch Monitoring",
        });
        setCurrentUser("Patch Monitoring");
        let globalFilters = JSON.parse(
          sessionStorage.getItem("globalFiltersIntialList")
        );
        if (globalFilters && globalFilters.length) {
          let singleFilterValuesObj =
            globalFilters && globalFilters.length
              ? globalFilters.map((el, index) => {
                  return {
                    id: el["id"],
                    value: el["columnValue"],
                    type: el["type"],
                    name: el["columnName"],
                  };
                })
              : [];
          let patchmonitorUpdatedData;
          console.log(singleFilterValuesObj, "singleFilterValuesObj");
          if (singleFilterValuesObj.length) {
            patchmonitorUpdatedData = cloneDeep(
              addGlobalFiltersForDashboard(
                [...updatedPatchMonotoringdashboardInstance],
                singleFilterValuesObj,
                masterArr
              )
            );
          } else {
            patchmonitorUpdatedData = [
              ...updatedPatchMonotoringdashboardInstance,
            ];
          }
          dispatch({
            type: "CHANGE_MDR_DASHBOARD",
            payload: [...patchmonitorUpdatedData],
          });
        } else {
          console.log(
            updatedPatchMonotoringdashboardInstance,
            "updatedPatchMonotoringdashboardInstance"
          );
          dispatch({
            type: "CHANGE_MDR_DASHBOARD",
            payload: updatedPatchMonotoringdashboardInstance,
          });
        }
        break;
        case "Risk View":
          let riskPilotInstance = [...RiskPilotDashboardInstance];
          dispatch({
            type: "CHANGE_MDR_DASHBOARD",
            payload: riskPilotInstance,
          });
          dispatch({
            type: "UPDATE_MDR_DROPDOWN_VALUE",
            payload: "Risk View",
          });
          setCurrentUser("Risk View");
        break;
      default:
    }
    setDashboardConfig(dashboardConfig);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const renderOptions = () => {
    if (allServices) {
      return allServices.map((data) => {
        return <MenuItem value={data.name}>{data.name}</MenuItem>;
      });
    } else {
      return <></>;
    }
  };

  const changeUser = (event) => {
    if (localStorage.getItem("access_token") === null) {
      if (window.location.host.indexOf("localhost") !== -1) {
        window.location.replace(
          `http://localhost:3000/?tenant=${localStorage.getItem("tenant")}`
        );
      } else {
        window.location.replace(`http://${window.location.host}/`);
      }
    }
    let dashboardConfig;
    localStorage.setItem(
      "mdrCurrentUser",
      event.target.value === "Patch Monitoring"
        ? event.target.value
        : event.target.value
    );

    switch (event.target.value) {
      case "Incidents":
        //To make the default chart as bar chart when toggling between dashbords after selected areachart in incident dashboards
        const incdata = [...IncidentdashboardInstance];
        let newObj = { ...incdata[5] };
        let newObj1 = { ...newObj.vizState };
        newObj1 = {
          chartType: "MDRBarGraph",
          query: {
            dimensions: ["Incidents.priority"],
            timeDimensions: [
              {
                dimension: "Incidents.creation_date",
                granularity: "day",
                // dateRange: "Last 7 days",
              },
            ],
            measures: ["Incidents.count"],
            filters: [],
          },
        };
        newObj.vizState = newObj1;
        incdata[5] = newObj;
        let incidentInstance = [...incdata];

        //To make the default chart as bar chart when toggling between dashbords after selected areachart in incident dashboards

        let updatedIncidentsdashboardInstance = updateQueryAsperDateRange(
          incidentInstance,
          {
            payload: calculateStartdateAndEndDate(),
          },
          dateTimeRange
        );
        dispatch({
          type: "INCIDENT_TABLE_FILTER_CLEARALL",
        });
        dispatch({
          type: "CHANGE_MDR_DASHBOARD",
          payload: updatedIncidentsdashboardInstance,
        });
        dispatch({ type: "UPDATE_MDR_DROPDOWN_VALUE", payload: "Incidents" });
        setCurrentUser("Incidents");
        break;
      case "Authentication":
        let data = [...AuthenticationdashboardInstance];
        let updatedAuthenticationdashboardInstance = updateQueryAsperDateRange(
          data,
          {
            payload: calculateStartdateAndEndDate(),
          },
          dateTimeRange
        );
        dispatch({
          type: "MDR_AUTHENTICATION_FILTERS_CLEARALL",
        });
        dispatch({
          type: "CHANGE_MDR_DASHBOARD",
          payload: updatedAuthenticationdashboardInstance,
        });
        dispatch({
          type: "UPDATE_MDR_DROPDOWN_VALUE",
          payload: "Authentication",
        });
        setCurrentUser("Authentication");

        break;
      case "Endpoints":
        // EndPointsdashboardInstance
        let data3 = [...EndPointsdashboardInstance];
        let updatedEndPointdashboardInstance = updateQueryAsperDateRange(
          data3,
          {
            payload: calculateStartdateAndEndDate(),
          },
          dateTimeRange
        );
        dispatch({
          type: "CHANGE_MDR_DASHBOARD",
          payload: updatedEndPointdashboardInstance,
        });

        dispatch({
          type: "UPDATE_MDR_DROPDOWN_VALUE",
          payload: "Endpoints",
        });

        setCurrentUser("Endpoints");

        break;
      case "Patch Monitoring":
        let patchmonitorUpdatedData = [
          ...PatchMonitoringdashboardInstanceClone(),
        ];
        let updatedPatchMonotoringdashboardInstance = updateQueryAsperDateRange(
          patchmonitorUpdatedData,
          {
            payload: calculateStartdateAndEndDate(),
          },
          dateTimeRange
        );
        dispatch({
          type: "UPDATE_MDR_DROPDOWN_VALUE",
          payload: "Patch Monitoring",
        });
        setCurrentUser("Patch Monitoring");
        let globalFilters = JSON.parse(
          sessionStorage.getItem("globalFiltersIntialList")
        );
        if (globalFilters && !globalFilters.length) {
          dispatch({
            type: "CHANGE_MDR_DASHBOARD",
            payload: updatedPatchMonotoringdashboardInstance,
          });
        } else {
          let singleFilterValuesObj = globalFilters.map((el, index) => {
            return {
              id: el["id"],
              value: el["columnValue"],
              type: el["type"],
              name: el["columnName"],
            };
          });
          let patchmonitorUpdatedData = cloneDeep(
            addGlobalFiltersForDashboard(
              [...updatedPatchMonotoringdashboardInstance],
              singleFilterValuesObj,
              masterArr
            )
          );
          dispatch({
            type: "CHANGE_MDR_DASHBOARD",
            payload: [...patchmonitorUpdatedData],
          });
        }
        break;
        case "Risk View":
          let riskPilotInstance = [...RiskPilotDashboardInstance];
          dispatch({
            type: "CHANGE_MDR_DASHBOARD",
            payload: riskPilotInstance,
          });
          dispatch({
            type: "UPDATE_MDR_DROPDOWN_VALUE",
            payload: "Risk View",
          });
          setCurrentUser("Risk View");
        break;
      default:
    }
    setDashboardConfig(dashboardConfig);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Text in a modal</h2>
      <p id="simple-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </p>
    </div>
  );
  let cubejsApi = getCubejsApi();

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <DashboardBanner
        project="MDR"
        currentUser={currentUser}
        renderOptions={renderOptions}
        changeUser={changeUser}
      ></DashboardBanner>
      <div className="main-dashboard-container" style={{ paddingTop: "15px" }}>
        <Grid container spacing={1}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            {currentUser === "Patch Monitoring" && (
              <DashboardMDRGlobalFilters
                globalFiltersIntialList={globalFiltersIntialList}
                setGlobalFilters={setGlobalFilters}
              ></DashboardMDRGlobalFilters>
            )}
          </Grid>
          {dashboardConfig &&
            dashboardConfig.length > 0 &&
            dashboardConfig.map((dashBoradinstance, index) => {
              return (
                <>
                  <Grid
                    key={index}
                    className={dashBoradinstance.row}
                    item
                    xs={dashBoradinstance.size}
                  >
                    {getChart(dashboardConfig, dashBoradinstance.id, cubejsApi)}
                  </Grid>
                </>
              );
            })}
          <TableFiltersLoad currentUser={currentUser}></TableFiltersLoad>
        </Grid>
      </div>
    </CubeProvider>
  );
}
export default React.memo(DashboardMDRWidgets);
